import * as z from './base';
import { objectUtil } from '../helpers/objectUtil';
import { isScalar } from '../isScalar';
const AugmentFactory = (def) => (augmentation) => {
    return new ZodObject(Object.assign(Object.assign({}, def), { shape: () => (Object.assign(Object.assign({}, def.shape()), augmentation)) }));
};
const objectDefToJson = (def) => ({
    t: def.t,
    shape: Object.assign({}, Object.keys(def.shape()).map(k => ({
        [k]: def.shape()[k].toJSON(),
    }))),
});
export class ZodObject extends z.ZodType {
    constructor() {
        super(...arguments);
        this.toJSON = () => objectDefToJson(this._def);
        this.nonstrict = () => new ZodObject({
            shape: this._def.shape,
            t: z.ZodTypes.object,
            params: Object.assign(Object.assign({}, this._params), { strict: false }),
        });
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        // nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.augment = AugmentFactory(this._def);
        this.extend = AugmentFactory(this._def);
        /**
         * Prior to zod@1.0.12 there was a bug in the
         * inferred type of merged objects. Please
         * upgrade if you are experiencing issues.
         */
        this.merge = objectUtil.mergeObjects(this);
        this.pick = (mask) => {
            const shape = {};
            Object.keys(mask).map(key => {
                shape[key] = this.shape[key];
            });
            return new ZodObject(Object.assign(Object.assign({}, this._def), { shape: () => shape }));
        };
        this.omit = (mask) => {
            const shape = {};
            Object.keys(this.shape).map(key => {
                if (Object.keys(mask).indexOf(key) === -1) {
                    shape[key] = this.shape[key];
                }
            });
            return new ZodObject(Object.assign(Object.assign({}, this._def), { shape: () => shape }));
        };
        this.partial = () => {
            const newShape = {};
            for (const key in this.shape) {
                newShape[key] = this.shape[key].optional();
            }
            return new ZodObject(Object.assign(Object.assign({}, this._def), { shape: () => newShape }));
        };
        this.primitives = () => {
            const newShape = {};
            for (const key in this.shape) {
                if (isScalar(this.shape[key])) {
                    newShape[key] = this.shape[key];
                }
            }
            return new ZodObject(Object.assign(Object.assign({}, this._def), { shape: () => newShape }));
        };
        this.nonprimitives = () => {
            const newShape = {};
            for (const key in this.shape) {
                if (!isScalar(this.shape[key])) {
                    newShape[key] = this.shape[key];
                }
            }
            return new ZodObject(Object.assign(Object.assign({}, this._def), { shape: () => newShape }));
        };
        this.deepPartial = () => {
            const newShape = {};
            for (const key in this.shape) {
                const fieldSchema = this.shape[key];
                if (fieldSchema instanceof ZodObject) {
                    newShape[key] = fieldSchema.deepPartial().optional();
                }
                else {
                    newShape[key] = this.shape[key].optional();
                }
            }
            return new ZodObject(Object.assign(Object.assign({}, this._def), { shape: () => newShape }));
        };
    }
    get shape() {
        return this._def.shape();
    }
    get params() {
        return this._def.params;
    }
}
ZodObject.create = (shape) => {
    return new ZodObject({
        t: z.ZodTypes.object,
        shape: () => shape,
        params: {
            strict: true,
        },
    });
};
ZodObject.lazycreate = (shape) => {
    return new ZodObject({
        t: z.ZodTypes.object,
        shape,
        params: {
            strict: true,
        },
    });
};
