export var util;
(function (util) {
    function assertNever(_x) {
        throw new Error();
    }
    util.assertNever = assertNever;
    util.arrayToEnum = (items) => {
        const obj = {};
        for (const item of items) {
            obj[item] = item;
        }
        return obj;
    };
    util.getValidEnumValues = (obj) => {
        const validKeys = Object.keys(obj).filter((k) => typeof obj[obj[k]] !== 'number');
        const filtered = {};
        for (const k of validKeys) {
            filtered[k] = obj[k];
        }
        return util.getValues(filtered);
    };
    util.getValues = (obj) => {
        return Object.keys(obj).map(function (e) {
            return obj[e];
        });
    };
})(util || (util = {}));
