<script>
  export let traderConfigId;

  import { useQuery } from "@sveltestack/svelte-query";
  import { getAssets } from "App/api/DashboardApi";
  import formatCurrencyNumber from "App/utils/formatCurrencyNumber";
  import toNumber from "App/utils/toNumber";
  import FetchButton from "./FetchButton.svelte";

  const queryResult = useQuery(
    ["dashboard/assets", { id: traderConfigId }],
    getAssets,
    { refetchInterval: 5 * 60 * 1000 }
  );
</script>

{#if $queryResult.isLoading}
  <span>Loading Assets...</span>
{:else if $queryResult.isError}
  <span>Error: {$queryResult.error.message}</span>
{:else}
  <div class="buttons is-right" style="margin-top: 1rem; margin-bottom: 0px">
    <FetchButton
      fetchDate="{$queryResult.data.fetchDate}"
      refetchQueries="{['dashboard/assets']}"
    />
  </div>
  {#if $queryResult.data.isEmpty}
    <div class="box is-marginless">
      <p class="is-center">No assets</p>
    </div>
  {:else}
    <div class="level is-mobile">
      {#each $queryResult.data.assets as asset (asset.currency)}
        <div class="level-item has-text-centered box">
          <div>
            <p class="heading">{asset.currency}</p>
            <p class="title is-spaced">
              {formatCurrencyNumber(toNumber(asset.amount), asset.currency)}
            </p>
          </div>
        </div>
      {/each}
    </div>
  {/if}
{/if}
