import { defineModelForFields } from "App/model";

const fields = [
  {
    name: "phone",
    type: "text",
    label: "Phone",
    defaultValue: () => "",
    validate: {
      // XXX: Custom validation. Abuse quality validator.
      equality: {
        attribute: "phone", // Just use any other attribute!
        message: "Valid international phone number",
        comparator: (phone, _) => {
          const strippedPhone = phone.replace(/\s/g, "");
          return /^\+[0-9]+$/.test(strippedPhone);
        },
      },
    },
  },
];

export default defineModelForFields(fields);
