import { defineModelForFields } from "App/model";
import formatTradingThreshold from "App/utils/formatTradingThreshold";

function makeFields(api_key_options, traded_coins) {
  const api_key_field = {
    name: "api_key",
    type: "select",
    label: "API Key",
    defaultValue: () => "",
    options: api_key_options,
    validate: {
      // presence: { allowEmpty: false },
    },
  };
  const trading_threshold_field = {
    name: "trading_threshold",
    type: "range",
    label: "Trading threshold",
    defaultValue: () => 50,
    valueFormatter: formatTradingThreshold,
    min: 1,
    max: 300,
    step: 1,
    validate: {
      // presence: { allowEmpty: false },
    },
  };
  const coin_ratio_field = {
    name: "coin_ratios",
    type: "multi-ratio-slider",
    label: "Coin ratios",
    defaultValue: () => {
      let ratios = {};
      traded_coins.forEach((coin) => {
        ratios[coin] = 0;
      });
      return ratios;
    },
    entries: traded_coins,
    valueFormatter: formatTradingThreshold,
    min: 0,
    max: 1000,
    step: 1,
    validate: {
      // XXX: Custom validation. Abuse quality validator.
      equality: {
        attribute: "trading_threshold", // Just use any other attribute!
        message: "does not add up to 100%",
        comparator: (coin_ratio, _) => {
          let total = traded_coins.reduce(
            (acc, cur) => acc + (coin_ratio[cur] || 0),
            0
          );
          return total === 1000;
        },
      },
    },
  };
  const comment_field = {
    name: "comment",
    type: "text",
    label: "Comment (optional)",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: true },
    },
  };

  return [
    api_key_field,
    trading_threshold_field,
    traded_coins.length > 0 ? coin_ratio_field : null,
    comment_field,
  ].filter((e) => e);
}

export default (api_key_options, traded_coins) =>
  defineModelForFields(makeFields(api_key_options, traded_coins));
