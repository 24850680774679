<script>
  export let field;
  export let model;
  export let placeholder = "";
</script>

<textarea
  class="textarea"
  bind:value="{model.values[field]}"
  placeholder="{placeholder}"
></textarea>
