import BaseApi from "App/api/BaseApi";
import OkResponse from "App/schemas/api/OkResponse";
import OkReasonResponse from "App/schemas/api/OkReasonResponse";
import EmptyResponse from "App/schemas/api/EmptyResponse";
import ListApiKeysResponse from "App/schemas/api/ListApiKeysResponse";
import ListTradersResponse from "App/schemas/api/ListTradersResponse";
import ListTraderConfigurationsResponse from "App/schemas/api/ListTraderConfigurationsResponse";
import GetTraderConfigurationResponse from "App/schemas/api/GetTraderConfigurationResponse";
import ListTraderConfigurationBalancesResponse from "App/schemas/api/ListTraderConfigurationBalancesResponse";
import ListTraderConfigurationActionsResponse from "App/schemas/api/ListTraderConfigurationActionsResponse";
import ListTraderConfigurationAssetsResponse from "App/schemas/api/ListTraderConfigurationAssetsResponse";
import CreateTraderConfigurationResponse from "App/schemas/api/CreateTraderConfigurationResponse";

export class TradingApi extends BaseApi {
  async listApiKeys() {
    const req = this._requestBuilder()
      .logContext(this.listApiKeys.name, undefined)
      .GET()
      .withSession()
      .path(["trading", "api_keys"])
      .build();

    let { api_keys } = await this._executeExpectJson(req, ListApiKeysResponse);
    return api_keys;
  }

  async addApiKey({ exchange, api_key, api_secret, comment, customer_id }) {
    const req = this._requestBuilder()
      .logContext(this.addApiKey.name, {
        exchange,
        api_key,
        api_secret,
        comment,
        customer_id,
      })
      .POST()
      .withSession()
      .jsonBody({ exchange, api_key, api_secret, comment, customer_id })
      .path(["trading", "api_keys", "add"])
      .build();

    let { ok } = await this._executeExpectJson(req, OkResponse);
    return ok;
  }

  async deleteApiKey(api_key) {
    const req = this._requestBuilder()
      .logContext(this.deleteApiKey.name, { api_key })
      .POST()
      .withSession()
      .jsonBody({ api_key })
      .path(["trading", "api_keys", "delete"])
      .build();

    let { ok } = await this._executeExpectJson(req, OkResponse);
    return ok;
  }

  async listTraders() {
    const req = this._requestBuilder()
      .logContext(this.listTraders.name, undefined)
      .GET()
      .withSession()
      .path(["traders"])
      .build();

    let { traders } = await this._executeExpectJson(req, ListTradersResponse);
    return traders;
  }

  async listTraderConfigurations() {
    const req = this._requestBuilder()
      .logContext(this.listTraderConfigurations.name, undefined)
      .GET()
      .withSession()
      .path(["trader_configurations"])
      .build();

    let { trader_configurations } = await this._executeExpectJson(
      req,
      ListTraderConfigurationsResponse
    );
    return trader_configurations;
  }

  async getTraderConfiguration(trader_configuration_id) {
    const req = this._requestBuilder()
      .logContext(this.listTraderConfigurations.name, {
        trader_configuration_id,
      })
      .GET()
      .withSession()
      .path(["trader_configurations", trader_configuration_id.toString()])
      .build();

    let { trader_configuration } = await this._executeExpectJson(
      req,
      GetTraderConfigurationResponse
    );
    return trader_configuration;
  }

  async deleteTraderConfiguration(trader_configuration_id) {
    const req = this._requestBuilder()
      .logContext(this.deleteTraderConfiguration.name, {
        trader_configuration_id,
      })
      .DELETE()
      .withSession()
      .noBody()
      .path(["trader_configurations", trader_configuration_id.toString()])
      .build();

    await this._executeExpectJson(req, EmptyResponse);
  }

  async enableTraderConfiguration(trader_configuration_id) {
    const req = this._requestBuilder()
      .logContext(this.enableTraderConfiguration.name, {
        trader_configuration_id,
      })
      .POST()
      .withSession()
      .noBody()
      .path([
        "trader_configurations",
        trader_configuration_id.toString(),
        "enable",
      ])
      .build();

    let { ok, reason } = await this._executeExpectJson(req, OkReasonResponse);
    return { ok, reason };
  }

  async disableTraderConfiguration(trader_configuration_id) {
    const req = this._requestBuilder()
      .logContext(this.disableTraderConfiguration.name, {
        trader_configuration_id,
      })
      .POST()
      .withSession()
      .noBody()
      .path([
        "trader_configurations",
        trader_configuration_id.toString(),
        "disable",
      ])
      .build();

    let { ok, reason } = await this._executeExpectJson(req, OkReasonResponse);
    return { ok, reason };
  }

  async updateTraderConfiguration(
    trader_configuration_id,
    { api_key, options, comment, trading_threshold_permille, coin_ratios }
  ) {
    const request = {
      api_key,
      options: options || {},
      comment: comment || "",
      trading_threshold_permille,
      coin_ratios: coin_ratios || {},
    };
    const req = this._requestBuilder()
      .logContext(this.updateTraderConfiguration.name, {
        trader_configuration_id,
        request,
      })
      .PUT()
      .withSession()
      .jsonBody(request)
      .path(["trader_configurations", trader_configuration_id.toString()])
      .build();

    await this._executeExpectJson(req, EmptyResponse);
  }

  async createTraderConfiguration({
    trader_id,
    api_key,
    options,
    comment,
    trading_threshold_permille,
    coin_ratios,
  }) {
    const request = {
      trader_id,
      api_key,
      options: options || {},
      comment: comment || "",
      trading_threshold_permille,
      coin_ratios: coin_ratios || {},
    };
    const req = this._requestBuilder()
      .logContext(this.createTraderConfiguration.name, request)
      .POST()
      .withSession()
      .jsonBody(request)
      .path(["trader_configurations"])
      .build();

    let { trader_configuration_id } = await this._executeExpectJson(
      req,
      CreateTraderConfigurationResponse
    );
    return trader_configuration_id;
  }

  async listTraderConfigurationBalances(
    trader_configuration_id,
    currency,
    from,
    to
  ) {
    const req = this._requestBuilder()
      .logContext(this.listTraderConfigurationBalances.name, {
        trader_configuration_id,
        currency,
        from,
        to,
      })
      .GET()
      .withSession()
      .path([
        "trader_configurations",
        trader_configuration_id.toString(),
        "balances",
        currency,
      ])
      .query({ from, to })
      .build();

    let { balances } = await this._executeExpectJson(
      req,
      ListTraderConfigurationBalancesResponse
    );
    return balances;
  }

  async listTraderConfigurationAssets(trader_configuration_id) {
    const req = this._requestBuilder()
      .logContext(this.listTraderConfigurationAssets.name, {
        trader_configuration_id,
      })
      .GET()
      .withSession()
      .path([
        "trader_configurations",
        trader_configuration_id.toString(),
        "assets",
      ])
      .build();

    let { validity, assets } = await this._executeExpectJson(
      req,
      ListTraderConfigurationAssetsResponse
    );
    return { validity, assets };
  }

  async listTraderConfigurationActions(trader_configuration_id, limit) {
    const req = this._requestBuilder()
      .logContext(this.listTraderConfigurationActions.name, {
        trader_configuration_id,
        limit,
      })
      .GET()
      .withSession()
      .path([
        "trader_configurations",
        trader_configuration_id.toString(),
        "actions",
      ])
      .query({ limit })
      .build();

    let { actions } = await this._executeExpectJson(
      req,
      ListTraderConfigurationActionsResponse
    );
    return actions;
  }
}

export default () => new TradingApi();
