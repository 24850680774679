<script>
  import { onMount } from "svelte";
  import VerificationApi from "App/api/VerificationApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";

  export let onError;
  export let onSuccess;

  let status = { type: "__fetching__" };

  onMount(async () => {
    status = await fetchStatus();
  });

  async function fetchStatus() {
    return await VerificationApi()
      .getVerificationStatus()
      .catch((ex) =>
        ApiErrorHandler(ex, () =>
          onError("Failed to fetch verification status")
        )
      );
  }

  async function startVerification() {
    try {
      let { ok, error } = await VerificationApi().startVerification();
      if (ok) {
        onSuccess();
      } else {
        onError(error);
      }
    } catch (ex) {
      ApiErrorHandler(ex);
    }
  }
</script>

{#if status.type === '__fetching__'}
  Fetching status...
{:else if status.type === 'Verified'}
  <div class="notification is-success">
    Your account is verified! Congratulations. <a href="/account/dashboard">Go
      to the Dashboard</a> and start trading.
  </div>
{:else if status.type === 'Submitted'}
  <div class="notification is-warning">
    Your verifiation was already submitted. Please wait until we take a look at
    your documents.
  </div>
{:else if status.type === 'Rejected'}
  <div class="notification is-danger">
    Your verification was rejected with the following reason: {status.reason}. <button class="button
        is-info is-pulled-right" on:click|preventDefault="{startVerification}"> Start
      new verification </button>
  </div>
{:else}
  <div class="has-text-centered">
    <p class="title">Start verification process</p>
    <p>
      In order to be able to use Aizu's full power, you have to go through a
      verification process.
    </p>
    <button
      class="button is-info is-pulled-right"
      on:click|preventDefault="{startVerification}"
    >
      Start
    </button>
    <div class="is-clearfix"></div>
  </div>
{/if}
