// XXX: Rename to sessionState

import { writable } from "svelte/store";

const INIT = "INIT";
const FETCHING = "FETCHING";
const OK = "OK";
const FAILED = "FAILED";

// We use a writable store that reflects the status of the ProfileApi.getProfile() calls.
const { subscribe, set, update } = writable({ type: INIT });

export const sessionFetchState = {
  subscribe,
  set,
  INIT,
  FETCHING,
  OK,
  FAILED,
};
