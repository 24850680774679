<script>
  import UnauthApi from "App/api/UnauthApi";
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import FullscreenDialog from "App/components/FullscreenDialog";
  import SeparatedLinks from "App/components/SeparatedLinks";
  import EnterTwoFactor from "App/components/Login/EnterTwoFactor";
  import Login2 from "App/components/Login/Login2";

  export let confirmationToken;
  export let nextPage = "/account/password/change";

  let loginFailed = writable(null);

  let state = { type: "in-progress" };

  onMount(async () => {
    state = { type: "in-progress" };

    try {
      let {
        credential_token,
        twofactor_check_token,
      } = await UnauthApi().confirmPassword(confirmationToken);
      onCredentialsEntered({ credential_token, twofactor_check_token });
    } catch (ex) {
      loginFailed.set(ex.error);
    }
  });

  function onCredentialsEntered({ credential_token, twofactor_check_token }) {
    if (twofactor_check_token) {
      state = {
        type: "2fa",
        credential_token,
        twofactor_check_token,
      };
    } else {
      state = {
        type: "login2",
        credential_token,
        twofactor_token: null,
      };
    }
  }

  function onTwoFactorCompleted({ twofactor_token }) {
    state = {
      type: "login2",
      credential_token: state.credential_token,
      twofactor_token,
    };
  }

  function onLogin2Completed() {
    state = { type: "logged-in" };
    if (nextPage) {
      navigate(nextPage, { replace: true });
    }
  }
</script>

<FullscreenDialog title="Confirm Password">
  {#if $loginFailed}
    <div class="notification is-danger">
      <p class="subtitle">
        Your confirmation token might have expired or is invalid. Please <a
          href="/account/password/reset"
        >reset your password</a> again.
      </p>
    </div>
  {:else}
    {#if state.type === 'in-progress'}
      <p class="subtitle">Confirmation in progress</p>
      <progress class="progress is-medium is-light" max="100">
        Confirmation in progress
      </progress>
    {/if}
    {#if state.type === '2fa'}
      <EnterTwoFactor
        loginFailed="{loginFailed}"
        next="{onTwoFactorCompleted}"
        twofactor_check_token="{state.twofactor_check_token}"
      />
    {/if}
    {#if state.type === 'login2'}
      <Login2
        loginFailed="{loginFailed}"
        next="{onLogin2Completed}"
        credential_token="{state.credential_token}"
        twofactor_token="{state.twofactor_token}"
      />
    {/if}
  {/if}

  <p slot="below">
    <SeparatedLinks
      links="{[{ href: '/account/password/reset', title: 'Reset Password' }, { href: '/account/login', title: 'Log in' }, { href: '/help', title: 'Need Help?' }]}"
    />
  </p>
</FullscreenDialog>
