<script>
  import {
    showTermsOfServices,
    showPrivacy,
  } from "./RegisterPageViewStores.js";

  function openTerms() {
    $showTermsOfServices = true;
  }

  function openPrivacy() {
    $showPrivacy = true;
  }
</script>

Yes, I do agree with the <a
  href="/terms"
  on:click|preventDefault="{openTerms}"
>Terms of Conditions</a> and <a
  href="/privacy"
  on:click|preventDefault="{openPrivacy}"
>Privacy Policy</a> .
