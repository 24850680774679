import { defineModelForFields } from "App/model";

const fields = [
  {
    name: "phone_confirmation_code",
    type: "text",
    label: "Phone confirmation code",
    defaultValue: () => "",
    validate: {
      numericality: true,
      length: { minimum: 6, maximum: 6 },
    },
  },
];

export default defineModelForFields(fields);
