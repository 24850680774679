export default function formatDateTime(date) {
  const datePart = date.toISOString().split("T")[0];
  const timePart = new Intl.DateTimeFormat("default", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  }).format(date);

  return [datePart, timePart].join("T");
}
