<script>
  import { Link } from "svelte-routing";

  export let to;

  const LinkClassActive = "navbar-item active";

  const LinkClassInactive = "navbar-item";

  function getProps({ isCurrent, isPartiallyCurrent }) {
    if (isCurrent || isPartiallyCurrent) {
      return { class: LinkClassActive };
    } else {
      return { class: LinkClassInactive };
    }
  }
</script>

<Link to="{to}" getProps="{getProps}">
  <slot />
</Link>
