<script>
  import UnauthApi from "App/api/UnauthApi";
  import RegisterModel from "App/models/RegisterModel";

  import CaptchaForm from "App/components/CaptchaForm.svelte";
  import FullscreenDialog from "App/components/FullscreenDialog.svelte";
  import ModelForm from "App/components/ModelForm";
  import SeparatedLinks from "App/components/SeparatedLinks.svelte";
  import {
    showTermsOfServices,
    showPrivacy,
  } from "./RegisterPageViewStores.js";
  import AgreeTermsRegistering from "App/components/AgreeTermsRegistering";
  import InfoDialog from "App/components/ui/InfoDialog";
  import PrivacyPolicyContent from "App/components/public/Content/PrivacyPolicyContent";
  import TermsContent from "App/components/public/Content/TermsContent";

  $showTermsOfServices = false;
  $showPrivacy = false;

  let state = { type: "init" };
  let model = RegisterModel(AgreeTermsRegistering)();

  function onSubmitValid() {
    state = { type: "challenge" };
  }

  function retry() {
    state = { type: "init" };
  }

  async function onGranted(grantToken) {
    state = { type: "sending" };

    await submitRegistration(grantToken, {
      first_name: model.valueFor("first_name"),
      last_name: model.valueFor("last_name"),
      email: model.valueFor("email"),
    });
  }

  async function submitRegistration(grantToken, request) {
    try {
      await UnauthApi().createRegistration(grantToken, request);
      state = { type: "success" };
    } catch (ex) {
      state = { type: "error", error: ex.error };
    }
  }
</script>

<InfoDialog
  title="Aizu Limited Terms of Service"
  visibilityStore="{showTermsOfServices}"
>
  <div class="content">
    <TermsContent />
  </div>
</InfoDialog>

<InfoDialog title="Privacy Notice" visibilityStore="{showPrivacy}">
  <div class="content">
    <PrivacyPolicyContent />
  </div>
</InfoDialog>

<FullscreenDialog title="Register an account">
  {#if state.type == 'init'}
    <p class="subtitle">Please enter your account details</p>
    <ModelForm
      model="{model}"
      onSubmitValid="{onSubmitValid}"
      withSubmitButton="Register"
    />
  {/if}

  {#if state.type == 'challenge'}
    <p class="subtitle">
      Please solve a captcha before your account registration is submitted...
    </p>
    <CaptchaForm onGranted="{onGranted}" />
  {/if}

  {#if state.type === 'sending'}
    <p class="subtitle">We are submitting your registration...</p>
    <progress class="progress is-medium is-light" max="100">
      Submitting registration
    </progress>
  {/if}

  {#if state.type === 'error'}
    <div class="notification is-danger">
      <button on:click="{retry}" class="delete">Retry</button> Failed to submit your
      registration: {state.error}
    </div>
  {/if}

  {#if state.type === 'success'}
    <p class="subtitle">Registration submitted!</p>
    <p>
      You'll need to confirm your e-mail before you can sign in. An e-mail
      confirmation link has been sent to you.
    </p>
  {/if}

  <p slot="below">
    <SeparatedLinks
      links="{[{ href: '/account/login', title: 'Log in' }, { href: '/help', title: 'Need Help?' }]}"
    />
  </p>
</FullscreenDialog>
