<script>
  export let profile;

  import ContentPage from "App/components/public/ContentPage";
  import ModelForm from "App/components/ModelForm";
  import ReportIssueModel from "App/models/ReportIssueModel";
  import IssuesApi from "App/api/IssuesApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import CaptchaForm from "App/components/CaptchaForm";

  function makeModel(profile) {
    let model = ReportIssueModel();
    if (profile) {
      model.values.name = [profile.first_name, profile.last_name]
        .filter((e) => e)
        .join(" ");
      model.values.email = profile.email;
      model.values.phone = profile.phone || "";
    }
    return model;
  }

  function isLoggedIn() {
    return profile && profile.email;
  }

  async function onSubmitValid({ name, email, phone, subject, description }) {
    let request = { name, email, phone, subject, description };
    if (isLoggedIn()) {
      await submitIssue(null, request);
    } else {
      state = { type: "challenge", request };
    }
  }

  function retry() {
    state = { type: "init" };
  }

  async function onGranted(grantToken) {
    await submitIssue(grantToken, state.request);
  }

  async function submitIssue(
    grantToken,
    { name, email, phone, subject, description }
  ) {
    state = { type: "sending" };

    try {
      let issue_id = await IssuesApi().createIssue(
        {
          reporter_name: name,
          reporter_email: email,
          reporter_phone: phone || null,
          issue_subject: subject,
          issue_description: description,
        },
        grantToken
      );
      state = { type: "success", issue_id };
    } catch (ex) {
      ApiErrorHandler(ex);
      state = { type: "error", error: "Failed to submit issue" };
    }
  }

  let model = makeModel(profile);
  let state = { type: "init" };
</script>

<ContentPage profile="{profile}" title="Report an issue">
  <div class="box">
    {#if state.type === 'init'}
      <p class="subtitle"></p>

      <ModelForm
        model="{model}"
        onSubmitValid="{onSubmitValid}"
        withSubmitButton="Report Issue"
      />
    {/if}

    {#if state.type === 'challenge'}
      <p class="subtitle">
        Please solve a captcha before your issue is reported...
      </p>
      <CaptchaForm onGranted="{onGranted}" />
    {/if}

    {#if state.type === 'sending'}
      <p class="subtitle">We are submitting your issue...</p>
      <progress class="progress is-medium is-light" max="100">
        Submitting issue
      </progress>
    {/if}

    {#if state.type === 'error'}
      <div class="notification is-danger">
        <button on:click="{retry}" class="delete">Retry</button> Failed to submit
        your registration: {state.error}
      </div>
    {/if}

    {#if state.type === 'success'}
      <p class="subtitle">Thank you. We created issue #{state.issue_id}!</p>
    {/if}
  </div>
</ContentPage>
