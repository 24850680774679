import * as z from './base';
// import { ZodUndefined } from './undefined';
// import { ZodNull } from './null';
// import { ZodUnion } from './union';
import { ZodErrorCode } from '../ZodError';
import { errorUtil } from '../helpers/errorUtil';
export class ZodNumber extends z.ZodType {
    constructor() {
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        super(...arguments);
        // null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.toJSON = () => this._def;
        this.min = (minimum, message) => this._refinement(Object.assign({ check: data => data >= minimum, code: ZodErrorCode.too_small, minimum, type: 'number', inclusive: true }, errorUtil.errToObj(message)));
        this.max = (maximum, message) => this._refinement(Object.assign({ check: data => data <= maximum, code: ZodErrorCode.too_big, maximum, type: 'number', inclusive: true }, errorUtil.errToObj(message)));
        this.int = (message) => this._refinement(Object.assign({ check: data => Number.isInteger(data), code: ZodErrorCode.invalid_type, expected: 'integer', received: 'number' }, errorUtil.errToObj(message)));
        this.positive = (message) => this._refinement(Object.assign({ check: data => data > 0, code: ZodErrorCode.too_small, minimum: 0, type: 'number', inclusive: false }, errorUtil.errToObj(message)));
        this.negative = (message) => this._refinement(Object.assign({ check: data => data < 0, code: ZodErrorCode.too_big, maximum: 0, type: 'number', inclusive: false }, errorUtil.errToObj(message)));
        this.nonpositive = (message) => this._refinement(Object.assign({ check: data => data <= 0, code: ZodErrorCode.too_big, maximum: 0, type: 'number', inclusive: true }, errorUtil.errToObj(message)));
        this.nonnegative = (message) => this._refinement(Object.assign({ check: data => data >= 0, code: ZodErrorCode.too_small, minimum: 0, type: 'number', inclusive: true }, errorUtil.errToObj(message)));
    }
}
ZodNumber.create = () => {
    return new ZodNumber({
        t: z.ZodTypes.number,
    });
};
