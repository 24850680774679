<script>
  import { useQuery, Query } from "@sveltestack/svelte-query";
  import { getBalances } from "App/api/DashboardApi";
  import Plot from "App/components/ui/Plot";
  import FetchButton from "./FetchButton.svelte";
  import formatCurrencyNumber from "App/utils/formatCurrencyNumber";

  export let traderConfigId;
  export let currency;
  export let label = "Total " + currency;
  export let timerange = "day";

  const MINUTES = 60 * 1000;

  const plotOptsFn = (ySize) => ({
    width: 600,
    height: 400,
    series: [
      { time: true },
      {
        label,
        spanGaps: false,
        stroke: "red",
        value: (self, value) =>
          value == null ? "-" : formatCurrencyNumber(value, currency),
        grid: { show: false },
      },
    ],
    axes: [{ time: true }, { size: ySize }],
  });

  const makeQueryOpts = (timerange, enabled) => ({
    queryKey: [
      "dashboard/balances",
      { id: traderConfigId, currency, timerange },
    ],
    queryFn: getBalances,
    refetchInterval: 5 * MINUTES,
    enabled,
  });

  let qAllOpts = makeQueryOpts("all", timerange === "all");
  $: qAllOpts = makeQueryOpts("all", timerange === "all");
  let qDayOpts = makeQueryOpts("day", timerange === "day");
  $: qDayOpts = makeQueryOpts("day", timerange === "day");
  let qWeekOpts = makeQueryOpts("week", timerange === "week");
  $: qWeekOpts = makeQueryOpts("week", timerange === "week");
  let qMonthOpts = makeQueryOpts("month", timerange === "month");
  $: qMonthOpts = makeQueryOpts("month", timerange === "month");

  const qAll = useQuery(qAllOpts.queryKey, qAllOpts.queryFn, qAllOpts);
  const qDay = useQuery(qDayOpts.queryKey, qDayOpts.queryFn, qDayOpts);
  const qWeek = useQuery(qWeekOpts.queryKey, qWeekOpts.queryFn, qWeekOpts);
  const qMonth = useQuery(qMonthOpts.queryKey, qMonthOpts.queryFn, qMonthOpts);

  $: {
    qAll.setOptions(qAllOpts.queryKey, qAllOpts.queryFn, qAllOpts);
  }
  $: {
    qDay.setOptions(qDayOpts.queryKey, qDayOpts.queryFn, qDayOpts);
  }
  $: {
    qWeek.setOptions(qWeekOpts.queryKey, qWeekOpts.queryFn, qWeekOpts);
  }
  $: {
    qMonth.setOptions(qMonthOpts.queryKey, qMonthOpts.queryFn, qMonthOpts);
  }

  let isLoading;
  let isError;
  let isSuccess;
  let error;
  let data;

  $: {
    if (timerange === "all") {
      isLoading = $qAll.isLoading;
      isError = $qAll.isError;
      error = $qAll.isError && $qAll.error.message;
      isSuccess = $qAll.isSuccess;
      data = $qAll.isSuccess && $qAll.data;
    } else if (timerange === "day") {
      isLoading = $qDay.isLoading;
      isError = $qDay.isError;
      error = $qDay.isError && $qDay.error.message;
      isSuccess = $qDay.isSuccess;
      data = $qDay.isSuccess && $qDay.data;
    } else if (timerange === "week") {
      isLoading = $qWeek.isLoading;
      isError = $qWeek.isError;
      error = $qWeek.isError && $qWeek.error.message;
      isSuccess = $qWeek.isSuccess;
      data = $qWeek.isSuccess && $qWeek.data;
    } else if (timerange === "month") {
      isLoading = $qMonth.isLoading;
      isError = $qMonth.isError;
      error = $qMonth.isError && $qMonth.error.message;
      isSuccess = $qMonth.isSuccess;
      data = $qMonth.isSuccess && $qMonth.data;
    }
  }

  let plotOpts;
  let plotData;

  $: plotOpts = isSuccess && data && plotOptsFn(data.ySize);
  $: plotData = isSuccess && data && [data.timeRow, data.dataRow];
</script>

<div class="buttons is-right" style="margin-bottom: 0px">
  <button
    class:button="{true}"
    class:is-small="{true}"
    class:is-light="{true}"
    class:is-active="{timerange === 'day'}"
    on:click|preventDefault="{() => (timerange = 'day')}"
  >Day</button>

  <button
    class:button="{true}"
    class:is-small="{true}"
    class:is-light="{true}"
    class:is-active="{timerange === 'week'}"
    on:click|preventDefault="{() => (timerange = 'week')}"
  >Week</button>

  <button
    class:button="{true}"
    class:is-small="{true}"
    class:is-light="{true}"
    class:is-active="{timerange === 'month'}"
    on:click|preventDefault="{() => (timerange = 'month')}"
  >Month</button>

  <button
    class:button="{true}"
    class:is-small="{true}"
    class:is-light="{true}"
    class:is-active="{timerange === 'all'}"
    on:click|preventDefault="{() => (timerange = 'all')}"
  >All</button>

  <FetchButton
    fetchDate="{isSuccess && data ? data.fetchDate : null}"
    refetchQueries="{['dashboard/balances']}"
  />
</div>

{#if isLoading}
  <div class="box" style="overflow: hidden; height: 400px;">
    <p class="is-center">Loading Plot...</p>
  </div>
{:else if isError}
  <div class="box" style="overflow: hidden; height: 400px;">
    <p class="is-center">Error: {error}</p>
  </div>
{:else if isSuccess && data}
  {#if data.isEmpty}
    <div class="box" style="overflow: hidden; height: 400px;">
      <p class="is-center">Plot has no data</p>
    </div>
  {:else}
    <div class="box" style="overflow: hidden;">
      <Plot opts="{plotOpts}" data="{plotData}" />
    </div>
  {/if}
{:else}<span>Unexpected Error</span>{/if}
