import * as z from './base';
export class ZodNull extends z.ZodType {
    constructor() {
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        super(...arguments);
        // null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.toJSON = () => this._def;
    }
}
ZodNull.create = () => {
    return new ZodNull({
        t: z.ZodTypes.null,
    });
};
