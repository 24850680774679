import * as z from './base';
export class ZodIntersection extends z.ZodType {
    constructor() {
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        super(...arguments);
        // null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.toJSON = () => ({
            t: this._def.t,
            left: this._def.left.toJSON(),
            right: this._def.right.toJSON(),
        });
    }
}
ZodIntersection.create = (left, right) => {
    return new ZodIntersection({
        t: z.ZodTypes.intersection,
        left: left,
        right: right,
    });
};
