<script>
  export let field;
  export let label;
  export let labelComponent = null;
  export let model;
  $: error = model.errorFor(field);
</script>

<div class="field">
  <label class="checkbox">
    <input
      type="checkbox"
      class="checkbox"
      bind:checked="{model.values[field]}"
    />
    {#if label}{label}{/if}
    {#if labelComponent}
      <svelte:component this="{labelComponent}" />
    {/if}
  </label>

  {#if error}
    <p class="help is-danger">{error}</p>
  {/if}
</div>
