import * as z from './base';
export class ZodUndefined extends z.ZodType {
    constructor() {
        super(...arguments);
        this.toJSON = () => this._def;
    }
}
// opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
// null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
ZodUndefined.create = () => {
    return new ZodUndefined({
        t: z.ZodTypes.undefined,
    });
};
