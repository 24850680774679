<script>
  import { onMount } from "svelte";
  import UnauthApi from "App/api/UnauthApi";
  import TextInputFieldWithContainer from "App/components/ui/TextInputFieldWithContainer.svelte";
  import CaptchaModel from "App/models/CaptchaModel";

  export let onGranted;

  let state = { type: "loading" };

  let model = CaptchaModel();

  onMount(loadCaptcha);

  async function loadCaptcha() {
    state = { type: "loading" };
    await getNewChallenge();
  }

  async function getNewChallenge() {
    await UnauthApi()
      .createChallenge()
      .then(({ id, png }) => {
        state = { type: "challenge", id, png };
      })
      .catch((err) => {
        state = { type: "error", error: "Failed to acquire challenge" };
      });
  }

  async function checkSolution(id) {
    const solution = model.valueFor("solution");

    const res = await UnauthApi().solveChallenge(id, solution);
    if (res.type === "Accept" && res.grant_token) {
      await onGranted(res.grant_token);
    } else if (res.type === "Incorrect" && res.trials_left > 0) {
      model.values.solution = "";
      model.errors = {
        solution: [`Incorrect solution. ${res.trials_left} attempt(s) left.`],
      };
    } else {
      model.values.solution = "";
      await getNewChallenge();
    }
  }

  async function onSubmit() {
    model = model.validate();
    if (model.isValid()) {
      await checkSolution(state.id).catch((err) => {
        state = { type: "error", error: "Failed to solve challenge" };
      });
    }
  }
</script>

{#if state.type === 'loading'}
  <progress class="progress is-medium is-light" max="100">
    Loading Captcha
  </progress>
{/if}

{#if state.type === 'error'}
  <div class="notification is-danger">
    <button on:click="{loadCaptcha}" class="delete"></button>
    {state.error}
  </div>
{/if}

{#if state.type === 'challenge'}
  <form on:submit|preventDefault="{onSubmit}" novalidate>
    <img alt="Captcha Challenge" src="data:image/gif;base64,{state.png}" />
    <p><a on:click|preventDefault="{loadCaptcha}">Reload Captcha</a></p>

    <TextInputFieldWithContainer
      field="solution"
      label="Solution"
      model="{model}"
    />
    <div class="field">
      <button type="submit" class="button is-block is-danger is-fullwidth">
        Check Solution
      </button>
    </div>
  </form>
{/if}
