import { ZodParser } from '../parser';
import { ZodErrorCode, ZodArray, ZodUnion, ZodNull, ZodUndefined, ZodError } from '../index';
export var ZodTypes;
(function (ZodTypes) {
    ZodTypes["string"] = "string";
    ZodTypes["number"] = "number";
    ZodTypes["bigint"] = "bigint";
    ZodTypes["boolean"] = "boolean";
    ZodTypes["date"] = "date";
    ZodTypes["undefined"] = "undefined";
    ZodTypes["null"] = "null";
    ZodTypes["array"] = "array";
    ZodTypes["object"] = "object";
    ZodTypes["union"] = "union";
    ZodTypes["intersection"] = "intersection";
    ZodTypes["tuple"] = "tuple";
    ZodTypes["record"] = "record";
    ZodTypes["function"] = "function";
    ZodTypes["lazy"] = "lazy";
    ZodTypes["literal"] = "literal";
    ZodTypes["enum"] = "enum";
    ZodTypes["nativeEnum"] = "nativeEnum";
    ZodTypes["promise"] = "promise";
    ZodTypes["any"] = "any";
    ZodTypes["unknown"] = "unknown";
    ZodTypes["void"] = "void";
})(ZodTypes || (ZodTypes = {}));
export class ZodType {
    constructor(def) {
        this.safeParse = (data, params) => {
            try {
                const parsed = this.parse(data, params);
                return {
                    success: true,
                    data: parsed,
                };
            }
            catch (err) {
                if (err instanceof ZodError) {
                    return {
                        success: false,
                        error: err,
                    };
                }
                throw err;
            }
        };
        this.parseAsync = (value, params) => {
            return new Promise((res, rej) => {
                try {
                    const parsed = this.parse(value, params);
                    return res(parsed);
                }
                catch (err) {
                    return rej(err);
                }
            });
        };
        this.refine = (check, message = 'Invalid value.') => {
            if (typeof message === 'string') {
                return this.refinement({ check, message });
            }
            return this.refinement(Object.assign({ check }, message));
        };
        this.refinement = (refinement) => {
            return this._refinement(Object.assign({ code: ZodErrorCode.custom_error }, refinement));
        };
        this._refinement = refinement => {
            return new this.constructor(Object.assign(Object.assign({}, this._def), { checks: [...(this._def.checks || []), refinement] }));
        };
        //  abstract // opt optional: () => any;
        this.optional = () => ZodUnion.create([this, ZodUndefined.create()]);
        this.nullable = () => ZodUnion.create([this, ZodNull.create()]);
        this.array = () => ZodArray.create(this);
        this.or = arg => {
            return ZodUnion.create([this, arg]);
        };
        this._def = def;
        this.parse = ZodParser(def);
    }
    is(u) {
        try {
            this.parse(u);
            return true;
        }
        catch (err) {
            return false;
        }
    }
    check(u) {
        try {
            this.parse(u);
            return true;
        }
        catch (err) {
            return false;
        }
    }
}
