<script>
  export let field = "email";
  export let model;
  export let placeholder = "Email";
  export let disabled = false;
  $: error = model.errorFor(field);
</script>

<input
  type="email"
  required
  class="input"
  class:is-danger="{error}"
  bind:value="{model.values[field]}"
  placeholder="{placeholder}"
  disabled="{disabled}"
/>
