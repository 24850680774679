import BaseApi from "App/api/BaseApi";
import CreateChallengeResponse from "App/schemas/api/CreateChallengeResponse";
import SolveChallengeResponse from "App/schemas/api/SolveChallengeResponse";
import ConfirmPasswordResponse from "App/schemas/api/ConfirmPasswordResponse";
import ConfirmRegistrationResponse from "App/schemas/api/ConfirmRegistrationResponse";

export class UnauthApi extends BaseApi {
  async createRegistration(grantToken, { first_name, last_name, email }) {
    const req = this._requestBuilder()
      .logContext("createRegistration", {
        grantToken,
        first_name,
        last_name,
        email,
      })
      .POST()
      .path("/registration/new")
      .jsonBody({
        token: grantToken,
        registration: { first_name, last_name, email },
      })
      .build();

    await this._executeExpectOk(req);
  }

  async confirmRegistration(confirmationToken) {
    const req = this._requestBuilder()
      .logContext("confirmRegistration", { confirmationToken })
      .POST()
      .path(`/registration/confirm/${confirmationToken}`)
      .noBody()
      .build();

    const { session_token } = await this._executeExpectJson(
      req,
      ConfirmRegistrationResponse
    );

    return { session_token };
  }

  async createChallenge() {
    const req = this._requestBuilder()
      .logContext("createChallenge", undefined)
      .POST()
      .path("/challenge/new")
      .noBody()
      .build();

    const { id, png } = await this._executeExpectJson(
      req,
      CreateChallengeResponse
    );
    return { id, png };
  }

  async solveChallenge(id, solution) {
    const req = this._requestBuilder()
      .logContext("solveChallenge", {
        id,
        solution,
      })
      .POST()
      .path("/challenge/solution")
      .jsonBody({ id, solution })
      .build();

    const { type, grant_token, trials_left } = await this._executeExpectJson(
      req,
      SolveChallengeResponse
    );
    return { type, grant_token, trials_left };
  }

  async resetPassword(grantToken, { email }) {
    const req = this._requestBuilder()
      .logContext("resetPassword", {
        grantToken,
        email,
      })
      .POST()
      .path("/password/reset")
      .jsonBody({ token: grantToken, email })
      .build();

    await this._executeExpectOk(req);
  }

  async confirmPassword(confirmationToken) {
    const req = this._requestBuilder()
      .logContext("confirmPassword", { confirmationToken })
      .POST()
      .path(`/password/confirm/${confirmationToken}`)
      .noBody()
      .build();

    const obj = await this._executeExpectJson(req, ConfirmPasswordResponse);
    return obj;
  }
}

export default () => new UnauthApi();
