<script>
  import { Router, Route, links, navigate } from "svelte-routing";
  import LoginPage from "App/components/Login/LoginPage";
  import RegisterPage from "App/components/RegisterPage";
  import Logout from "App/components/Logout";
  import AccountPage from "App/components/AccountPage";
  import ResetPasswordPage from "App/components/Password/ResetPasswordPage";
  import ConfirmPasswordPage from "App/components/Password/ConfirmPasswordPage";
  import VerificationWizard from "App/components/Verification/VerificationWizard";
  import Profile from "App/components/Profile";
  import Dashboard from "App/components/Dashboard/Dashboard";
  import ListTraderConfigurations from "App/components/TraderConfigurations/List";
  import EditTraderConfiguration from "App/components/TraderConfigurations/Edit";
  import CreateTraderConfiguration from "App/components/TraderConfigurations/Create";
  import ListApiKeys from "App/components/ApiKeys/List";
  import AddApiKey from "App/components/ApiKeys/Add";
  import ConfirmAccount from "App/components/ConfirmAccount";
  import ChangePassword from "App/components/ChangePassword";
  import ListTraderConfigurationActions from "App/components/Dashboard/ListTraderConfigurationActions";
  import PublicIndexPage from "App/components/public/IndexPage";
  import PublicPrivacyPage from "App/components/public/PrivacyPage";
  import PublicDisclaimerPage from "App/components/public/DisclaimerPage";
  import PublicTermsPage from "App/components/public/TermsPage";
  import PublicFaqPage from "App/components/public/FaqPage";

  import PublicAboutPage from "App/components/public/AboutPage";
  import PublicContactPage from "App/components/public/ContactPage";
  import PublicHelpPage from "App/components/public/HelpPage";

  import FetchProfileOnce from "App/components/FetchProfileOnce";
  import ErrorsView from "App/components/ErrorsView";
  import SecureYourAccount from "App/components/Config/SecureYourAccount";
  import ReportIssuePage from "App/components/Issues/Create";
  import TryFetchProfile from "App/components/TryFetchProfile";

  import profile from "App/stores/profile";

  import { QueryClient, QueryClientProvider } from "@sveltestack/svelte-query";

  const queryClient = new QueryClient();
</script>

<ErrorsView showLast="{5}" />

<QueryClientProvider client="{queryClient}">
  <div use:links>
    <Router basepath="/">
      <Route path="/privacy">
        <TryFetchProfile>
          <PublicPrivacyPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>
      <Route path="/terms">
        <TryFetchProfile>
          <PublicTermsPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>
      <Route path="/disclaimer">
        <TryFetchProfile>
          <PublicDisclaimerPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>
      <Route path="/faq">
        <TryFetchProfile>
          <PublicFaqPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>

      <Route path="/help">
        <TryFetchProfile>
          <PublicHelpPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>

      <Route path="/about">
        <TryFetchProfile>
          <PublicAboutPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>

      <Route path="/contact">
        <TryFetchProfile>
          <PublicContactPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>

      <Route path="/">
        <TryFetchProfile>
          <PublicIndexPage profile="{$profile}" />
        </TryFetchProfile>
      </Route>
    </Router>

    <Router basepath="/report">
      <Route path="issue">
        <TryFetchProfile>
          <ReportIssuePage profile="{$profile}" />
        </TryFetchProfile>
      </Route>
    </Router>

    <Router basepath="/account">
      <Route path="relogin">
        <LoginPage nextPage="/account/dashboard" />
      </Route>
      <Route path="login">
        <FetchProfileOnce
          onOkRedirect="/account/dashboard"
          ignoreSessionError="{true}"
        />
        <LoginPage />
      </Route>
      <Route path="logout" component="{Logout}" />
      <Route path="register" component="{RegisterPage}" />

      <Route path="password/reset" component="{ResetPasswordPage}" />

      <Route path="password/confirm/:confirmationToken" let:params>
        <ConfirmPasswordPage confirmationToken="{params.confirmationToken}" />
      </Route>

      <Route path="password/change">
        <ChangePassword />
      </Route>

      <Route path="password/choose">
        <ChangePassword title="Choose your Password" />
      </Route>

      <Route path="confirm/:confirmationToken" let:params>
        <ConfirmAccount confirmationToken="{params.confirmationToken}" />
      </Route>

      <Route path="dashboard">
        <AccountPage component="{Dashboard}" />
      </Route>

      <Route path="profile">
        <AccountPage component="{Profile}" />
      </Route>

      <Route path="trader_configurations">
        <AccountPage component="{ListTraderConfigurations}" />
      </Route>

      <Route path="trader_configurations/new">
        <AccountPage component="{CreateTraderConfiguration}" />
      </Route>

      <Route
        path="trader_configurations/:traderConfigurationId/edit"
        let:params
      >
        <AccountPage
          component="{EditTraderConfiguration}"
          params="{{ trader_configuration_id: params.traderConfigurationId }}"
        />
      </Route>

      <Route
        path="trader_configurations/:traderConfigurationId/actions"
        let:params
      >
        <AccountPage
          component="{ListTraderConfigurationActions}"
          params="{{ trader_configuration_id: params.traderConfigurationId }}"
        />
      </Route>

      <Route path="api_keys">
        <AccountPage component="{ListApiKeys}" />
      </Route>

      <Route path="api_keys/new">
        <AccountPage component="{AddApiKey}" />
      </Route>

      <Route path="verification/*">
        <AccountPage
          component="{VerificationWizard}"
          showVerificationWarning="{false}"
        />
      </Route>

      <Route path="config/secure">
        <AccountPage
          component="{SecureYourAccount}"
          showVerificationWarning="{false}"
        />
      </Route>
    </Router>
  </div>
</QueryClientProvider>
