<script>
  import Assets from "./Assets.svelte";
  import Actions from "./Actions.svelte";
  import DashboardPlot from "./DashboardPlot.svelte";

  export let traderConfigId;
  export let traderName;

  let open = true;
</script>

<div class="dashboard-trader">
  <!-- svelte-ignore a11y-missing-attribute -->
  <a class="divider" on:click|preventDefault="{() => (open = !open)}">
    {traderName}
  </a>
  <div class:is-hidden="{!open}">
    <DashboardPlot
      traderConfigId="{traderConfigId}"
      currency="USD"
      label="Total USD"
    />
    <Assets traderConfigId="{traderConfigId}" />
    <Actions traderConfigId="{traderConfigId}" limit="{2}" />
  </div>
</div>
