<script>
  export let traders;
  export let onCancel;
  export let onTraderSelected;

  import ModelForm from "App/components/ModelForm";
  import SelectTraderModel from "App/models/SelectTraderModel";

  function trader_to_option(trader) {
    return {
      id: trader.id.toString(),
      text: [
        trader.name,
        trader.description && `(${trader.description})`,
        `#${trader.id.toString()}`,
      ]
        .filter((e) => e)
        .join(" "),
    };
  }

  function makeModel(traders) {
    let trader_options = [{ id: "", text: "Please select trader" }].concat(
      traders.map(trader_to_option)
    );
    let model = SelectTraderModel(trader_options)();
    return model;
  }

  let model = makeModel(traders);

  async function onSubmitValid({ trader }) {
    let trader_id = parseInt(trader);
    let selectedTrader = traders.find((trader) => trader.id === trader_id);
    if (selectedTrader) {
      await onTraderSelected(selectedTrader);
    } else {
      throw `Failed to lookup trader with id: ${trader_id}`;
    }
  }
</script>

<div class="card mt-6">
  <header class="card-header">
    <p class="card-header-title">Create new Trader configuration</p>
  </header>
  <div class="card-content">
    <ModelForm
      model="{model}"
      onSubmitValid="{onSubmitValid}"
      withSubmitButton="Next"
    >
      <div class="field is-grouped" slot="buttons">
        <div class="control">
          <button type="submit" class="button is-block is-danger">Next</button>
        </div>
        <div class="control">
          <button class="button is-block" on:click|preventDefault="{onCancel}">
            Cancel
          </button>
        </div>
      </div>
    </ModelForm>
  </div>
</div>
