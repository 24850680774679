import { defineModelForFields } from "App/model";

const fields = [
  {
    name: "email",
    type: "email",
    defaultValue: () => "",
    validate: {
      email: true,
    },
  },
  {
    name: "password",
    type: "password",
    defaultValue: () => "",
    validate: {
      //presence: {allowEmpty: false},
      length: { minimum: 8, maximum: 64 },
    },
  },
];

export default defineModelForFields(fields);
