<script>
  export let onOkRedirect = null;
  export let onErrRedirect = null;
  export let ignoreSessionError = false;

  import { sessionFetchState } from "App/stores/sessionFetchState";
  import { session } from "App/stores/session";
  import { navigate } from "svelte-routing";
  import ApiErrorHandler from "App/api/ApiErrorHandler";

  $: if ($sessionFetchState.type === sessionFetchState.INIT) {
    session
      .fetchProfile()
      .catch((ex) =>
        ApiErrorHandler(ex, null, ignoreSessionError ? "ignore" : "show")
      );
  }

  $: if (onOkRedirect && $sessionFetchState.type === sessionFetchState.OK) {
    navigate(onOkRedirect);
  }

  $: if (
    onErrRedirect &&
    $sessionFetchState.type === sessionFetchState.FAILED
  ) {
    navigate(onErrRedirect);
  }
</script>
