<script>
  export let profile = null;
  import ContentPage from "./ContentPage.svelte";
  import DisclaimerContent from "./Content/DisclaimerContent.svelte";
</script>

<ContentPage
  profile="{profile}"
  title="Disclaimer"
  content="{DisclaimerContent}"
/>
