<script>
  import TraderConfigurationModel from "App/models/TraderConfigurationModel";
  import ModelForm from "App/components/ModelForm";

  export let traderConfig = null;
  export let traderInfo;
  export let apiKeys;
  export let onCancel;
  export let onSubmitValid;

  function format_api_key_str(api_key) {
    return [
      api_key.exchange ? "[" + api_key.exchange + "]" : null,
      formatApiKey(api_key.api_key),
      api_key.comment ? "(" + api_key.comment + ")" : null,
    ]
      .filter((e) => e)
      .join(" ");
  }

  function formatApiKey(api_key) {
    return api_key.substring(0, 16);
  }

  function api_key_to_option(api_key) {
    return {
      id: api_key.api_key,
      text: format_api_key_str(api_key),
    };
  }

  function makeModel(apiKeys, traderConfig, traderInfo) {
    let api_key_options = [{ id: "", text: "None" }].concat(
      apiKeys.map(api_key_to_option)
    );

    let traded_coins = traderInfo.traded_coins || [];
    let model = TraderConfigurationModel(api_key_options, traded_coins)();
    if (traderConfig) {
      model.values.api_key =
        (traderConfig.api_key_info && traderConfig.api_key_info.api_key) || "";
      model.values.comment = traderConfig.comment || "";
      model.values.trading_threshold = traderConfig.trading_threshold_permille;

      let coin_ratios = {};
      traded_coins.forEach((coin) => {
        coin_ratios[coin] = traderConfig.coin_ratios[coin] || 0;
      });
      model.values.coin_ratios = coin_ratios;
    }
    return model;
  }

  let model = makeModel(apiKeys, traderConfig, traderInfo);
</script>

<div class="card mt-6">
  <header class="card-header">
    <p class="card-header-title">
      {#if traderConfig}
        Configure {traderInfo.name} #{traderConfig.id}
      {:else}Create new Trader configuration for trader {traderInfo.name}{/if}
    </p>
  </header>
  <div class="card-content">
    <ModelForm
      model="{model}"
      onSubmitValid="{onSubmitValid}"
      withSubmitButton="{traderConfig ? 'Save' : 'Create'}"
    >
      <div class="field is-grouped" slot="buttons">
        <div class="control">
          <button type="submit" class="button is-block is-danger">
            {traderConfig ? 'Save' : 'Create'}
          </button>
        </div>
        <div class="control">
          <button class="button is-block" on:click|preventDefault="{onCancel}">
            Cancel
          </button>
        </div>
      </div>
    </ModelForm>
  </div>
</div>
