export default class RequestBuilder {
  constructor(pathPrefix) {
    this._log_context = undefined;
    this._method = undefined;
    this._path = undefined;
    this._body = undefined;
    this._query = undefined;
    this._noBody = false;
    this._headers = {};
    this._withSession = false;
    this._pathPrefix = pathPrefix;
  }

  logContext(method, params) {
    this._log_context = { method, params };
    return this;
  }

  GET() {
    if (this._method !== undefined) throw "method already set";
    this._method = "GET";
    return this;
  }

  POST() {
    if (this._method !== undefined) throw "method already set";
    this._method = "POST";
    return this;
  }

  PUT() {
    if (this._method !== undefined) throw "method already set";
    this._method = "PUT";
    return this;
  }

  DELETE() {
    if (this._method !== undefined) throw "method already set";
    this._method = "DELETE";
    return this;
  }

  path(_path) {
    if (this._path !== undefined) throw "path already set";
    this._path = _path;
    return this;
  }

  query(_query) {
    if (this._query !== undefined) throw "query already set";
    this._query = _query;
    return this;
  }

  noBody() {
    if (this._body !== undefined) throw "body already set";
    this._noBody = true;
    return this;
  }

  rawBody(_body) {
    if (this._body !== undefined) throw "body already set";
    if (this._noBody === true) throw "noBody already set";
    this._body = _body;
    return this;
  }

  jsonBody(obj) {
    if (this._body !== undefined) throw "body already set";
    this._body = JSON.stringify(obj);
    return this.contentType("application/json");
  }

  contentType(_contentType) {
    if (this._headers["Content-Type"] !== undefined)
      throw "Content-Type already set";
    this._headers["Content-Type"] = _contentType;
    return this;
  }

  withSession() {
    this._withSession = true;
    return this;
  }

  build() {
    if (this._method === undefined) throw "method not set";
    if (this._path === undefined) throw "path not set";
    if (
      (this._method === "POST" || this._method === "PUT") &&
      this._body === undefined &&
      this._noBody === false
    )
      throw "Neither body nor noBody";
    if (this._body !== undefined && this._noBody === true)
      throw "Both body and noBody";
    if (this._method === "GET" && this._body !== undefined)
      throw "GET with body not allowed";

    const url = this._makeUrl();

    const request = {
      method: this._method,
      headers: this._headers,
    };

    if (this._body !== undefined) {
      request.body = this._body;
    }

    if (this._withSession === true) {
      request.credentials = "same-origin"; // XXX
    }

    const log_context = this._log_context;
    return { url, request, log_context };
  }

  _makeQuery() {
    const query = this._query;

    if (query) {
      const q = Object.entries(query)
        .filter(([_k, value]) => !(value === undefined || value === null))
        .map((kv) => kv.map(encodeURIComponent).join("="))
        .join("&");
      if (q) {
        return "?" + q;
      }
    }

    return "";
  }

  _makeUrl() {
    const prefix = this._pathPrefix;
    const path = this._path;
    const query = this._makeQuery();

    if (typeof path === "string") {
      return prefix + path + query;
    } else if (typeof path === "object") {
      return [prefix, ...path.map(encodeURIComponent)].join("/") + query;
    } else {
      throw "Invaid type for path";
    }
  }
}
