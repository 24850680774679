<script>
  import { Router, Route } from "svelte-routing";
  import { navigate } from "svelte-routing";
  import VerificationStatus from "App/components/Verification/VerificationStatus";
  import StepOrigin from "App/components/Verification/StepOrigin";
  import StepPhone from "App/components/Verification/StepPhone";
  import StepPhoneConfirmation from "App/components/Verification/StepPhoneConfirmation";
  import StepPassport from "App/components/Verification/StepPassport";
  import StepUtilityBill from "App/components/Verification/StepUtilityBill";
  import StepSubmit from "App/components/Verification/StepSubmit";
  import StepDone from "App/components/Verification/StepDone";

  let error = null;
  function onError(errorMsg) {
    error = errorMsg || "An unknown error occured";
  }
</script>

<div class="mt-6">
  {#if error}
    <div class="notification is-danger">
      <p class="title">An error occured</p>
      <p class="subtitle">{error}</p>
      <button class="button" on:click="{() => (error = null)}"> Retry </button>
      <button class="button" on:click="{() => navigate('/account/dashboard')}">
        Abort
      </button>
    </div>
  {/if}

  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Account Verification</p>
    </header>

    <div class="card-content">
      <Router>
        <Route path="start">
          <VerificationStatus
            onError="{onError}"
            onSuccess="{() => navigate('/account/verification/origin')}"
          />
        </Route>
        <Route path="origin">
          <StepOrigin
            onError="{onError}"
            onSuccess="{() => navigate('/account/verification/phone')}"
          />
        </Route>
        <Route path="phone">
          <StepPhone
            onError="{onError}"
            onSuccess="{(phone) => navigate('/account/verification/phone_confirmation/' + encodeURIComponent(phone))}"
          />
        </Route>
        <Route path="phone_confirmation/:phone" let:params>
          <StepPhoneConfirmation
            onError="{onError}"
            onSuccess="{() => navigate('/account/verification/passport')}"
            phone="{params.phone}"
          />
        </Route>
        <Route path="passport">
          <StepPassport
            onError="{onError}"
            onSuccess="{() => navigate('/account/verification/utility_bill')}"
          />
        </Route>
        <Route path="utility_bill">
          <StepUtilityBill
            onError="{onError}"
            onSuccess="{() => navigate('/account/verification/submit')}"
          />
        </Route>
        <Route path="submit">
          <StepSubmit
            onError="{onError}"
            onSuccess="{() => navigate('/account/verification/done')}"
          />
        </Route>
        <Route path="done">
          <StepDone />
        </Route>
      </Router>
    </div>
  </div>
</div>
