<script>
  export let activeStep;

  import Steps from "App/components/Steps";

  const stepDefinitions = [
    {
      stepName: "origin",
      marker: "1",
      details: "Origin",
    },
    {
      stepName: "phone",
      marker: "2",
      details: "Phone",
    },
    {
      stepName: "phone_confirmation",
      marker: "3",
      details: "Phone Confirmation",
    },
    {
      stepName: "passport",
      marker: "4",
      details: "Passport",
    },
    {
      stepName: "utility_bill",
      marker: "5",
      details: "Utility Bill",
    },
    {
      stepName: "submit",
      marker: "6",
      details: "Submit",
    },
  ];

  function makeSteps(activeStepName) {
    var foundActiveStep = false;
    const steps = stepDefinitions.map((info, _index) => {
      const active = activeStepName === info.stepName;
      foundActiveStep = foundActiveStep || active;
      const s = {
        active,
        success: !foundActiveStep,
        completed: !foundActiveStep,
        marker: info.marker,
        details: info.details,
      };
      return s;
    });

    return steps;
  }

  let steps = makeSteps(activeStep);
</script>

<div class="card-image pt-1 has-background-white-bis">
  <Steps steps="{steps}" />
</div>
