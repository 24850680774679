import { util } from './helpers/util';
export const ZodErrorCode = util.arrayToEnum([
    'invalid_type',
    'nonempty_array_is_empty',
    'custom_error',
    'invalid_union',
    'invalid_literal_value',
    'invalid_enum_value',
    'unrecognized_keys',
    'invalid_arguments',
    'invalid_return_type',
    'invalid_date',
    'invalid_string',
    'too_small',
    'too_big',
]);
export const quotelessJson = (obj) => {
    const json = JSON.stringify(obj, null, 2); // {"name":"John Smith"}
    return json.replace(/"([^"]+)":/g, '$1:');
};
export class ZodError extends Error {
    constructor(errors) {
        super();
        this.errors = [];
        this.addError = (sub) => {
            this.errors = [...this.errors, sub];
        };
        this.addErrors = (subs = []) => {
            this.errors = [...this.errors, ...subs];
        };
        this.flatten = () => {
            const fieldErrors = {};
            const formErrors = [];
            for (const sub of this.errors) {
                if (sub.path.length > 0) {
                    fieldErrors[sub.path[0]] = fieldErrors[sub.path[0]] || [];
                    fieldErrors[sub.path[0]].push(sub.message);
                }
                else {
                    formErrors.push(sub.message);
                }
            }
            return { formErrors, fieldErrors };
        };
        // restore prototype chain
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        }
        else {
            this.__proto__ = actualProto;
        }
        this.errors = errors;
    }
    get message() {
        // return JSON.stringify(this.errors, null, 2);
        const errorMessage = [`${this.errors.length} validation issue(s)`, ''];
        for (const err of this.errors) {
            errorMessage.push(`  Issue #${this.errors.indexOf(err)}: ${err.code} at ${err.path.join('./index')}`);
            errorMessage.push(`  ` + err.message);
            errorMessage.push('');
        }
        return errorMessage.join('\n');
        // return quotelessJson(this);
        // .map(({ path, message }) => {
        //   return path.length ? `${path.join('./index')}: ${message}` : `${message}`;
        // })
        // .join('\n');
    }
    get isEmpty() {
        return this.errors.length === 0;
    }
    get formErrors() {
        return this.flatten();
    }
}
ZodError.create = (errors) => {
    const error = new ZodError(errors);
    return error;
};
