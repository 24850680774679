import validateJs from "validate.js";

function validate() {
  this.errors = validateJs(this.values, this.constraints);
  if (this.errors === undefined && this.customValidationFn) {
    this.errors = this.customValidationFn(this.values);
  }
  if (this.errors === undefined) {
    this.ifValid = (fn) => fn(this.values);
  } else {
    this.ifValid = (_fn) => {};
  }
  return this;
}

function isValid() {
  return this.errors === undefined;
}

function errorFor(fieldName) {
  return this.errors && this.errors[fieldName];
}

function valueFor(fieldName) {
  return this.values && this.values[fieldName];
}

function defineModel(constraints, defaultValuesFn, fields) {
  return (values, customValidationFn) => {
    return {
      constraints,
      values: Object.assign(defaultValuesFn(), values || {}),
      errors: undefined,
      errorFor,
      valueFor,
      validate,
      isValid,
      fields,
      defaultValues: defaultValuesFn,
      customValidationFn,
    };
  };
}

export function defineModelForFields(fields) {
  const constraints = makeConstraintsForFields(fields);
  const defaultValuesFn = makeDefaultValuesFnForFields(fields);
  return defineModel(constraints, defaultValuesFn, fields);
}

function makeConstraintsForFields(fields) {
  const constraints = {};
  fields.forEach((field) => {
    constraints[field.name] = field.validate;
  });
  return constraints;
}

function makeDefaultValuesFnForFields(fields) {
  return () => {
    const values = {};
    fields.forEach((field) => {
      values[field.name] = field.defaultValue();
    });
    return values;
  };
}
