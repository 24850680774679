import { writable } from "svelte/store";
import { sessionFetchState } from "./sessionFetchState";
import ProfileApi from "App/api/ProfileApi";

const { subscribe, set, update } = writable({});

function reset() {
  set({});
  sessionFetchState.set({ type: sessionFetchState.INIT });
}

async function fetchProfile() {
  sessionFetchState.set({ type: sessionFetchState.FETCHING });
  let profile = await ProfileApi().getProfile();
  set({ profile });
  sessionFetchState.set({ type: sessionFetchState.OK });
}

export const session = {
  set,
  subscribe,
  reset,
  fetchProfile,
};
