<script>
  import { onMount } from "svelte";
  import { useQueryClient } from "@sveltestack/svelte-query";

  export let fetchDate;
  export let refetchQueries;

  const queryClient = useQueryClient();

  let isRefetching = false;
  async function refetch() {
    isRefetching = true;
    await queryClient.refetchQueries(refetchQueries, { active: true });
    isRefetching = false;
  }

  let now = new Date();
  let secondsAgo;

  $: secondsAgo = fetchDate ? Math.trunc((now - fetchDate) / 1000) : null;

  function formatSeconds(sec) {
    if (sec < 60) {
      return `a few seconds ago`;
    }

    const mins = Math.trunc(sec / 60);
    return `${mins} minute${mins === 1 ? "" : "s"} ago`;
  }

  onMount(() => {
    const timer = setInterval(() => {
      now = new Date();
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  });
</script>

<button
  class="button is-round is-small is-light"
  on:click|preventDefault="{refetch}"
  class:is-loading="{isRefetching}"
  title="Last updated: {fetchDate ? fetchDate.toUTCString() : 'never'}"
>
  <span style="margin-right: 0.5rem">Updated: {fetchDate ? formatSeconds(secondsAgo) : 'never'}</span>
  <span class="icon is-small">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    ><path fill="none" d="M0 0h24v24H0z"></path>
      <path
        d="M5.463 4.433A9.961 9.961 0 0 1 12 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 0 0 6.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0 1 12 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0 0 13.54 5.772l.997 1.795z"
      ></path></svg>
  </span>
</button>
