<script>
  export let title = "";

  export let visibilityStore;

  function onHide() {
    $visibilityStore = false;
  }
</script>

<div class="modal" class:is-active="{$visibilityStore}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{title}</p>
      <button
        class="delete"
        aria-label="close"
        on:click|preventDefault="{onHide}"
      ></button>
    </header>
    <section class="modal-card-body">
      <slot />
    </section>
  </div>
</div>
