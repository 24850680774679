<script>
  export let title;
</script>

<div class="card mt-6">
  <header class="card-header">
    <p class="card-header-title">{title}</p>
  </header>
  <div class="card-content">
    <slot />
  </div>
  <slot name="footer" />
</div>
