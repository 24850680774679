import * as z from "../../../vendor/zod/src/index";
import Currency from "./Currency";
const Trader = z.object({
    id: z.number().int(),
    identifying_name: z.string(),
    name: z.string(),
    description: z.string(),
    traded_coins: z.array(Currency).nullable(),
});
export default z.object({
    traders: z.array(Trader),
});
