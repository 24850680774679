import { ZodTypes } from '../types/base';
import { ZodIntersection } from '../types/intersection';
import { ZodObject } from '../types/object';
export var objectUtil;
(function (objectUtil) {
    objectUtil.mergeShapes = (first, second) => {
        const firstKeys = Object.keys(first);
        const secondKeys = Object.keys(second);
        const sharedKeys = firstKeys.filter(k => secondKeys.indexOf(k) !== -1);
        const sharedShape = {};
        for (const k of sharedKeys) {
            sharedShape[k] = ZodIntersection.create(first[k], second[k]);
        }
        return Object.assign(Object.assign(Object.assign({}, first), second), sharedShape);
    };
    objectUtil.mergeObjects = (first) => (second) => {
        const mergedShape = objectUtil.mergeShapes(first._def.shape(), second._def.shape());
        const merged = new ZodObject({
            t: ZodTypes.object,
            checks: [...(first._def.checks || []), ...(second._def.checks || [])],
            params: {
                strict: first.params.strict && second.params.strict,
            },
            shape: () => mergedShape,
        });
        return merged;
    };
})(objectUtil || (objectUtil = {}));
