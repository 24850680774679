import { writable } from "svelte/store";
import TradingApi from "App/api/TradingApi";
import ApiErrorHandler from "App/api/ApiErrorHandler";

const { subscribe, set } = writable([]);
var fetched = false;

function forceReload() {
  return TradingApi()
    .listTraders()
    .then(set)
    .then(() => {
      fetched = true;
    })
    .catch(ApiErrorHandler);
}

async function load() {
  if (!fetched) {
    await forceReload();
  }
}

export default {
  subscribe,
  load,
  forceReload,
};
