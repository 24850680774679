<script>
  import ShowTraderConfiguration from "App/components/TraderConfigurations/Show";
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import TradingApi from "App/api/TradingApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import Icon from "mdi-svelte";
  import { mdiPlusThick } from "@mdi/js";

  let traderConfigurations = [];

  async function reload() {
    traderConfigurations = await TradingApi().listTraderConfigurations();
  }

  onMount(async () => {
    await reload().catch(ApiErrorHandler);
  });
</script>

<p class="mt-6">
  <a
    href="/account/trader_configurations/new"
    class="button is-link"
    aria-label="add new trader configuration"
  >
    <span class="icon">
      <i class="mdi">
        <Icon path="{mdiPlusThick}" />
      </i>
    </span>
    <span class="ml-1">Add new Trader configuration</span>
  </a>
</p>

{#each traderConfigurations as traderConfig (traderConfig.id)}
  <!-- XXX: use store depency instead of explicit "reload" command -->
  <ShowTraderConfiguration traderConfig="{traderConfig}" reload="{reload}" />
{/each}
