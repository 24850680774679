<script>
  export let field;
  export let model;
  export let placeholder = "";
  export let disabled;

  $: error = model.errorFor(field);
</script>

<input
  type="text"
  required
  class="input"
  class:is-danger="{error}"
  bind:value="{model.values[field]}"
  placeholder="{placeholder}"
  disabled="{disabled}"
/>
