<script>
  import InputFieldContainer from "App/components/ui/InputFieldContainer";
  import Icon from "mdi-svelte";
  import { mdiUpload } from "@mdi/js";

  export let onSubmitValid;

  let uploadField;
  let selectedFile = null;
  let image = null;
  let errors = [];

  const onChange = () => {
    selectedFile = uploadField && uploadField.files[0];
    image = null;
    errors = [];
    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        let reader = new FileReader();

        reader.onload = function (e) {
          image = e.target.result;
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const onSubmit = () => {
    if (selectedFile) {
      errors = [];
      onSubmitValid({ file: selectedFile });
    } else {
      errors = ["File required"];
    }
  };
</script>

<form on:submit|preventDefault="{onSubmit}" novalidate>
  <InputFieldContainer error="{errors[0]}">
    {#if image}
      <figure>
        <img src="{image}" width="50%" alt="Preview of uploaded document" />
      </figure>
    {/if}

    <div class="file has-name is-fullwidth">
      <label class="file-label">
        <input
          bind:this="{uploadField}"
          type="file"
          class="file-input"
          accept="image/png, image/jpeg, application/pdf"
          on:change="{onChange}"
        />

        <span class="file-cta">
          <span class="file-icon">
            <i class="mdi">
              <Icon path="{mdiUpload}" />
            </i>
          </span>
          <span class="file-label">Choose a file…</span>
        </span>
        <span class="file-name">
          {#if selectedFile && selectedFile.name}{selectedFile.name}{/if}
        </span>
      </label>
    </div>
  </InputFieldContainer>

  <div class="field">
    <button type="submit" class="button is-block is-danger is-fullwidth">
      Next
    </button>
  </div>
</form>
