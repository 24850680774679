import * as z from "../../../vendor/zod/src/index";
const Accept = z.object({
    type: z.literal("Accept"),
    grant_token: z.string(),
});
const Incorrect = z.object({
    type: z.literal("Incorrect"),
    trials_left: z.number().int(),
});
const TooManyTrials = z.object({
    type: z.literal("TooManyTrials"),
});
export default z.union([Accept, Incorrect, TooManyTrials]);
