<script>
  // styles taken from rfoel/bulma-toast
  import { errors } from "App/stores/errors";

  export let showLast = 5;
  export let opacity = 0.9;
</script>

<div
  style="width:100%;z-index:99999;position:fixed;pointer-events:none;display:flex;flex-direction:column;padding:15px;bottom:0;left:0;right:0;text-align:center;align-items:center;"
>
  {#each $errors.slice(0, showLast) as error (error.id)}
    <div
      class="notification is-danger"
      style="width:auto;pointer-events:auto;display:inline-flex;white-space:pre-wrap;opacity:{opacity};"
    >
      <button
        class="delete is-large"
        on:click="{() => errors.remove(error.id)}"
      ></button>
      <p class="subtitle">{error.message}</p>
    </div>
  {/each}
</div>
