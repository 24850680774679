<script>
  export let profile;
  import ProfileDropDown from "../ProfileDropDown.svelte";
</script>

{#if profile}
  <ProfileDropDown profile="{profile}" />
{:else}
  <div class="buttons">
    <a href="/account/register" class="button is-secondary">
      <strong>Sign up</strong>
    </a>
    <a href="/account/login" class="button is-light">Log in</a>
  </div>
{/if}
