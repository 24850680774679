import * as z from "../../../vendor/zod/src/index";
export default z.object({
    email: z.string().email(),
    username: z.string().min(1),
    first_name: z.string(),
    last_name: z.string(),
    phone: z.string().optional().nullable(),
    country: z.string().optional().nullable(),
    street_address: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    postal_code: z.string().optional().nullable(),
    nationality: z.string().optional().nullable(),
    is_verified: z.boolean(),
    is_account_secured: z.boolean(),
});
