import { writable } from "svelte/store";

const { subscribe, set, update } = writable([]);

var ids = 0;

function add(message, timeout) {
  const id = (ids += 1);
  update((old) => [{ message, id }].concat(old));
  setTimeout(() => remove(id), (timeout || 5) * 1000);
}

function remove(messageId) {
  update((old) => old.filter((e) => e.id != messageId));
}

export const errors = {
  subscribe,
  add,
  remove,
};
