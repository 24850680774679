<script>
  import { onMount } from "svelte";
  import uPlot from "uplot";

  export let data;
  export let opts;
  export let minHeight = 200;
  export let aspectRatio = 1.0 / 3.0;

  let container;
  let uplot;
  let width;
  let height;

  onMount(() => {
    uplot = new uPlot(Object.assign({ height, width }, opts), data, container);
  });

  $: if (uplot && width && width > 0) {
    uplot.setSize({ width, height: Math.max(minHeight, width * aspectRatio) });
  }
</script>

<div
  bind:this="{container}"
  bind:clientWidth="{width}"
  bind:clientHeight="{height}"
></div>
