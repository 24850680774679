<script>
  import ProfileApi from "App/api/ProfileApi";
  import { errors } from "App/stores/errors";
  import Icon from "mdi-svelte";
  import { mdiLockCheck } from "@mdi/js";

  export let updated;

  async function unconfigure() {
    const ok = await ProfileApi()
      .configTwoFactorAuth(null)
      .catch((_e) => false);
    if (ok) {
      updated();
    } else {
      errors.add("Failed to unconfigure 2FA");
    }
  }
</script>

<div class="media">
  <div class="media-left">
    <span class="icon is-large has-text-success">
      <i class="mdi mdi-48px" aria-hidden="true">
        <Icon path="{mdiLockCheck}" size="48px" />
      </i>
    </span>
  </div>
  <div class="media-content">
    <p>
      Your account is <strong class="has-text-success">secured</strong> with 2-factor
      authentication (2FA).
    </p>
  </div>
</div>

<p class="mt-6">
  <a
    href="#"
    on:click|preventDefault="{unconfigure}"
    class="button is-danger is-light"
  >
    Remove 2-factor authentication
  </a>
</p>
