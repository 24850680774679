<script>
  import profile from "App/stores/profile";
  import Icon from "mdi-svelte";
  import { mdiAlertCircle } from "@mdi/js";
</script>

{#if !$profile.is_verified}
  <section class="notification is-warning mt-6 mb-6">
    <div class="media">
      <div class="media-left">
        <span class="icon has-text-danger">
          <i class="mdi">
            <Icon path="{mdiAlertCircle}" />
          </i>
        </span>
      </div>
      <div class="media-content">
        Your account has not been verified yet. For this reason, trading is
        disabled. <a href="/account/verification/start">Start verification</a> .
      </div>
    </div>
  </section>
{/if}
