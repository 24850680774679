import { defineModelForFields } from "App/model";

const fields = [
  {
    name: "password",
    label: "Password",
    type: "password",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: false },
      length: { minimum: 8, maximum: 64 },
    },
  },
  {
    name: "confirm_password",
    label: "Confirm Password",
    type: "password",
    defaultValue: () => "",
    validate: {
      equality: "password",
    },
  },
];

export default defineModelForFields(fields);
