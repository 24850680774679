import * as z from "../../../vendor/zod/src/index";
const ApiKeyInfo = z.object({
    api_key: z.string(),
    comment: z.string(),
    exchange: z.string(),
});
const TraderConfiguration = z.object({
    id: z.number().int(),
    trader_id: z.number().int(),
    trader_name: z.string(),
    trader_description: z.string(),
    enabled: z.boolean(),
    api_key_info: ApiKeyInfo.nullable(),
    options: z.record(z.string()),
    comment: z.string(),
    trading_threshold_permille: z.number().int().min(0).max(1000),
    coin_ratios: z.record(z.number().int()),
});
export default TraderConfiguration;
