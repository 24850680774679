<script>
  import PhoneConfirmationModel from "App/models/verification/PhoneConfirmationModel";
  import ModelForm from "App/components/ModelForm";
  import VerificationApi from "App/api/VerificationApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import StepsProgress from "./StepsProgress.svelte";

  export let onError;
  export let onSuccess;
  export let phone;

  let model = PhoneConfirmationModel();

  async function submitPhoneConfirmation({ phone_confirmation_code }) {
    try {
      let { ok, error } = await VerificationApi().confirmPhone({
        phone,
        phone_confirmation_code,
      });
      if (ok) {
        onSuccess();
      } else {
        onError(error);
      }
    } catch (ex) {
      ApiErrorHandler(ex);
    }
  }
</script>

<StepsProgress activeStep="phone_confirmation" />

<p class="title">Enter 6-digit code</p>
<p class="subtitle">We've send you a message with the code to: {phone}</p>
<ModelForm
  onSubmitValid="{submitPhoneConfirmation}"
  model="{model}"
  withSubmitButton="Next"
/>
