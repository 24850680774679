import * as z from "../../../vendor/zod/src/index";
const NotStarted = z.object({
    type: z.literal("NotStarted"),
});
const Incomplete = z.object({
    type: z.literal("Incomplete"),
    address_missing: z.boolean(),
    phone_missing: z.boolean(),
    passport_missing: z.boolean(),
    utility_bill_missing: z.boolean(),
});
const Complete = z.object({
    type: z.literal("Complete"),
});
const Submitted = z.object({
    type: z.literal("Submitted"),
});
const Rejected = z.object({
    type: z.literal("Rejected"),
    reason: z.string(),
});
const Verified = z.object({
    type: z.literal("Verified"),
});
export default z.union([
    NotStarted,
    Incomplete,
    Complete,
    Submitted,
    Rejected,
    Verified,
]);
