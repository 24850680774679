<script>
  import { onMount, afterUpdate } from "svelte";
  import kjua from "kjua";

  export let value = "";
  export let color = "#333";
  export let size = 300;
  export let rounded = 0;

  let container;
  var qr;

  onMount(() => {
    qr = makeQR();
    container.appendChild(qr);
  });

  afterUpdate(() => {
    container.removeChild(qr);
    qr = makeQR();
    container.appendChild(qr);
  });

  function makeQR() {
    return kjua({
      rounded,
      text: value,
      fill: color,
      back: "transparent",
      size,
    });
  }
</script>

<div bind:this="{container}"></div>
