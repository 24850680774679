import { defineModelForFields } from "App/model";

function makeFields() {
  return [
    {
      name: "name",
      label: "Full name",
      type: "text",
      defaultValue: () => "",
      validate: {
        presence: { allowEmpty: false },
      },
    },
    {
      name: "email",
      type: "email",
      defaultValue: () => "",
      validate: {
        email: true,
      },
    },
    {
      name: "phone",
      type: "text",
      label: "Phone (optional)",
      defaultValue: () => "",
      validate: {
        // XXX: Custom validation. Abuse quality validator.
        equality: {
          attribute: "phone", // Just use any other attribute!
          message: "Valid international phone number",
          comparator: (phone, _) => {
            const strippedPhone = phone.replace(/\s/g, "");
            if (strippedPhone) {
              return /^\+[0-9]+$/.test(strippedPhone);
            }
            return true;
          },
        },
        /*
        // XXX: conditionalize format validation
        presence: {
          allowEmpty: true,
        },
        format: {
          pattern: "\\+[0-9]+",
          message: "Valid international phone number",
        },
        */
      },
    },
    {
      name: "subject",
      label: "Subject",
      type: "text",
      defaultValue: () => "",
      placeholder: "Short description what went wrong",
      validate: {
        presence: { allowEmpty: false },
      },
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      placeholder: "Please enter the full problem description here",
      defaultValue: () => "",
      validate: {
        presence: { allowEmpty: false },
      },
    },
  ];
}

export default defineModelForFields(makeFields());
