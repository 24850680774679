<script>
  import VerificationApi from "App/api/VerificationApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import StepsProgress from "./StepsProgress.svelte";

  export let onError;
  export let onSuccess;

  async function submitVerification() {
    try {
      let { ok, error } = await VerificationApi().submitVerification();
      if (ok) {
        onSuccess();
      } else {
        onError(error);
      }
    } catch (ex) {
      ApiErrorHandler(ex);
    }
  }
</script>

<StepsProgress activeStep="submit" />

<p class="title">Submit verification</p>
<button
  class="button is-info is-pulled-right"
  on:click|preventDefault="{submitVerification}"
>
  Submit Verification
</button>
<div class="is-clearfix"></div>
