<script>
  import { onMount } from "svelte";
  import api_keys from "App/stores/api_keys";
  import SupportedExchanges from "App/models/SupportedExchanges";
  import ConfirmDialog from "App/components/ui/ConfirmDialog";
  import Icon from "mdi-svelte";
  import { mdiPlusThick, mdiDelete } from "@mdi/js";

  function exchangeIdToName(id) {
    const el = SupportedExchanges.find((e) => e.id === id);
    return el && el.name;
  }

  onMount(() => api_keys.load());

  let selectedApiKey = null;

  function confirmDeleteKey(apiKey) {
    selectedApiKey = apiKey;
  }

  function cancelDeleteApiKey() {
    selectedApiKey = null;
  }

  function deleteApiKey() {
    if (selectedApiKey) {
      api_keys.deleteKey(selectedApiKey);
    }
    selectedApiKey = null;
  }

  function formatApiKey(api_key) {
    return api_key.substring(0, 16);
  }
</script>

<div class="card mt-6">
  <header class="card-header">
    <p class="card-header-title">Manage your API keys</p>
  </header>
</div>

<div class="mt-3">
  <table class="table is-fullwidth is-hoverable is-striped is-bordered">
    <thead>
      <tr>
        <th>Exchange</th>
        <th>API Key</th>
        <th>Comment</th>
        <th>Customer ID</th>
        <th>In use?</th>
        <th>
          <a
            href="/account/api_keys/new"
            class="button is-small is-link"
            aria-label="add new api key"
          >
            <span class="icon">
              <i class="mdi">
                <Icon path="{mdiPlusThick}" />
              </i>
            </span>
            <span class="ml-1">Add API Key</span>
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      {#if $api_keys.length > 0}
        {#each $api_keys as entry (entry.api_key)}
          <tr>
            <td>
              {#if entry.exchange}
                {exchangeIdToName(entry.exchange) || entry.exchange}
              {:else}<i>n/a</i>{/if}
            </td>
            <td>{formatApiKey(entry.api_key)}</td>
            <td>{entry.comment}</td>
            <td>
              {#if entry.customer_id}{entry.customer_id}{:else}<i>n/a</i>{/if}
            </td>
            <td>{entry.references > 0 ? 'Yes' : 'No'}</td>
            <td>
              <!-- svelte-ignore a11y-missing-attribute -->

              <a
                on:click|preventDefault="{() => confirmDeleteKey(entry.api_key)}"
                class="button is-danger is-small is-light"
              >
                <span class="icon">
                  <i class="mdi">
                    <Icon path="{mdiDelete}" />
                  </i>
                </span>
                <span class="ml-1">Delete</span>
              </a>
            </td>
          </tr>
        {/each}
      {:else}
        <tr>
          <td colspan="6">No API Keys</td>
        </tr>
      {/if}
    </tbody>
  </table>
</div>

<ConfirmDialog
  title="Deleting API Key"
  show="{selectedApiKey}"
  actionButtonTitle="Delete API key"
  onCancel="{cancelDeleteApiKey}"
  onAction="{deleteApiKey}"
>
  <p>
    Are you sure you want to <strong>delete</strong> API key <em
    >{selectedApiKey}</em> ? This action cannot be undone.
  </p>
</ConfirmDialog>
