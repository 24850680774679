<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import { session } from "App/stores/session";
  import SessionApi from "App/api/SessionApi";

  export let loginFailed;
  export let next;
  export let credential_token;
  export let twofactor_token;

  onMount(async () => {
    await login2(credential_token, twofactor_token);
  });

  async function login2(credential_token, twofactor_token) {
    try {
      let response = await SessionApi().login2(
        credential_token,
        twofactor_token
      );
      await SessionApi().persist(response.session_token);

      // combined with <FetchProfileOnce onOkRedirect="..." />
      // this will forward to the dashboard
      session.reset();
      next();
    } catch (ex) {
      loginFailed.set(`Login Failed: ${ex.error}`);
    }
  }
</script>

<p class="subtitle">Login in progress...</p>
<progress class="progress is-medium is-light" max="100">
  Login in progress...
</progress>
