<script>
  import ConfirmDialog from "App/components/ui/ConfirmDialog";
  export let show = false;
  export let onAction;
  export let onCancel;
</script>

<ConfirmDialog
  title="Log out"
  show="{show}"
  actionButtonTitle="Log out"
  onCancel="{onCancel}"
  onAction="{onAction}"
>
  <p>Are you sure you want to <strong>log out</strong> ?</p>
</ConfirmDialog>
