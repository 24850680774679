<script>
  // This tries to fetch the profile. Once it has finished fetching,
  // it renders the <slot/>

  import { sessionFetchState } from "App/stores/sessionFetchState";
  import { session } from "App/stores/session";
  import { onMount } from "svelte";
  import ApiErrorHandler from "App/api/ApiErrorHandler";

  let doneFetching = false;

  onMount(async () => {
    if ($sessionFetchState.type === sessionFetchState.INIT) {
      await session
        .fetchProfile()
        .catch((ex) => ApiErrorHandler(ex, null, "ignore"));
    }
    doneFetching = true;
  });
</script>

{#if doneFetching}
  <slot />
{/if}
