<script>
  import Nav from "./Nav.svelte";
  import Footer from "./Footer.svelte";
  export let profile = null;
</script>

<Nav profile="{profile}" />

<section class="hero is-medium is-light">
  <div class="hero-body">
    <div class="container">
      <div class="columns">
        <div class="column is-one-third">
          <h1 class="title is-1">
            Find the right <span class="is-bold has-text-info">Balance</span>
          </h1>

          <p class="subtitle is-4" style="padding-top: 1.5em;">
            We are a group of friends who, for years, have been developing and
            using a trading/rebalancing platform for our own private use. Aizu
            was born when friends wanted to take advantage.
          </p>

          <p class="subtitle is-4">
            Please note that this is very much still work in progress. We need
            more users who can help us shape our product by testing it and, most
            importantly, giving feedback.
          </p>
        </div>

        <div class="column">
          <div
            style=" padding-left: 4em; padding-right: 4em; padding-top: 1em;
            padding-bottom: 1em"
          >
            <div class="box has-background-white-ter">
              <figure class="image">
                <img src="/img/screenshot.png" alt="Screenshot of Aizu" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="hero is-medium">
  <div class="hero-body">
    <div class="container">
      <h1 class="title is-1 has-text-centered">
        So what does your <span class="is-bold has-text-info">product</span> do,
        exactly?
      </h1>

      <div class="features tile is-ancestor is-vertical has-text-justified">
        <div class="tile is-parent">
          <article class="tile is-child is-6">
            <p class="title is-4">Trading Algorithms</p>
            <p class="subtitle">
              Aizu enables the user to set up trading algorithms to buy/sell
              cryptocurrencies as well as gold, and rebalance between them
              according to the user’s preferences. It does this by using the
              APIs provided by your exchanges.
            </p>
          </article>

          <article class="tile is-child is-6">
            <p class="title is-4">Free access (for now)</p>
            <p class="subtitle">
              Aizu is very much still work in progress. We need more users who
              can help us shape our product by testing it and, most importantly,
              giving feedback. That is why you have free access to our platform.
            </p>
          </article>
        </div>

        <div class="tile is-parent">
          <article class="tile is-child is-6">
            <p class="title is-4">Supported Exchanges</p>
            <p class="subtitle">
              We currently support only a limited number of exchanges (Kraken
              and Bitstamp) and trading pairs (BTC, ETH, ADA, PAXG, USD) but we
              are already actively working on expanding both lists.
            </p>
          </article>
          <article class="tile is-child">
            <p class="title is-4">A Tool for you</p>
            <p class="subtitle">
              It is very important that you understand that we are not
              investment advisors or portfolio managers. We do not choose what
              you trade and when - we simply give you a tool that you can
              configure as you see fit. Before using our product, please make
              sure you thoroughly read and understand our Disclaimer, ToS and
              Privacy policy.
            </p>
          </article>
        </div>
      </div>
    </div>
  </div>
</section>

<Footer />
