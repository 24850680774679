export default function formatTradingThreshold(numberPerMille) {
  let significant = Math.trunc(numberPerMille / 10);
  let fraction = Math.trunc(numberPerMille % 10);

  return `${significant}.${fraction} %`;
  /*
      return new Intl.NumberFormat("en-US", {
        style: 'percent',
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 2,
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(number);
      */
}
