import BaseApi from "App/api/BaseApi";
import OkErrorResponse from "App/schemas/api/OkErrorResponse";
import VerificationStatusResponse from "App/schemas/api/VerificationStatusResponse";

export class VerificationApi extends BaseApi {
  async getVerificationStatus() {
    const req = this._requestBuilder()
      .logContext("getVerificationStatus", {})
      .GET()
      .withSession()
      .path("/verification/status")
      .build();

    let json = await this._executeExpectJson(req, VerificationStatusResponse);
    return json;
  }

  async startVerification() {
    const req = this._requestBuilder()
      .logContext("startVerification", {})
      .POST()
      .withSession()
      .jsonBody({})
      .path("/verification/start")
      .build();

    let { ok, error } = await this._executeExpectJson(req, OkErrorResponse);
    return { ok, error };
  }

  async saveAddress({
    country,
    street_address,
    city,
    postal_code,
    has_nationality_from_country,
  }) {
    const address = {
      country,
      street_address,
      city,
      postal_code,
      has_nationality_from_country,
    };

    const req = this._requestBuilder()
      .logContext("saveAddress", address)
      .POST()
      .withSession()
      .jsonBody(address)
      .path("/verification/address")
      .build();

    let { ok, error } = await this._executeExpectJson(req, OkErrorResponse);
    return { ok, error };
  }

  async savePhone({ phone }) {
    const req = this._requestBuilder()
      .logContext("savePhone", { phone })
      .POST()
      .withSession()
      .jsonBody({ phone })
      .path("/verification/phone")
      .build();

    let { ok, error } = await this._executeExpectJson(req, OkErrorResponse);
    return { ok, error };
  }

  async confirmPhone({ phone, phone_confirmation_code }) {
    const req = this._requestBuilder()
      .logContext("confirmPhone", { phone, phone_confirmation_code })
      .POST()
      .withSession()
      .jsonBody({ phone, phone_confirmation_code })
      .path("/verification/phone/confirm")
      .build();

    let { ok, error } = await this._executeExpectJson(req, OkErrorResponse);
    return { ok, error };
  }

  async uploadPassport(file) {
    // Content-Type is set automatically by fetch
    const req = this._requestBuilder()
      .logContext("uploadPassport", { file })
      .POST()
      .withSession()
      .rawBody(file)
      .path("/verification/passport")
      .build();

    let { ok, error } = await this._executeExpectJson(req, OkErrorResponse);
    return { ok, error };
  }

  async uploadUtilityBill(file) {
    // Content-Type is set automatically by fetch
    const req = this._requestBuilder()
      .logContext(this.uploadUtilityBill.name, { file })
      .POST()
      .withSession()
      .rawBody(file)
      .path("/verification/utility_bill")
      .build();
    let { ok, error } = await this._executeExpectJson(req, OkErrorResponse);
    return { ok, error };
  }

  async submitVerification() {
    const req = this._requestBuilder()
      .logContext(this.submitVerification.name, {})
      .POST()
      .withSession()
      .jsonBody({})
      .path("/verification/submit")
      .build();
    let { ok, error } = await this._executeExpectJson(req, OkErrorResponse);
    return { ok, error };
  }
}

export default () => new VerificationApi();
