<script>
  import PhoneModel from "App/models/verification/PhoneModel";
  import ModelForm from "App/components/ModelForm";
  import VerificationApi from "App/api/VerificationApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import StepsProgress from "./StepsProgress.svelte";

  export let onError;
  export let onSuccess;

  let model = PhoneModel();

  async function submitPhone({ phone }) {
    try {
      const strippedPhone = phone.replace(/\s/g, "");

      let { ok, error } = await VerificationApi().savePhone({
        phone: strippedPhone,
      });
      if (ok) {
        onSuccess(strippedPhone);
      } else {
        onError(error);
      }
    } catch (ex) {
      ApiErrorHandler(ex);
    }
  }
</script>

<StepsProgress activeStep="phone" />

<p class="title">Enter your phone number</p>
<p class="subtitle">We'll send you a message to confirm it's really you</p>
<ModelForm
  onSubmitValid="{submitPhone}"
  model="{model}"
  withSubmitButton="Next"
/>
