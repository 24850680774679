<script>
  export let error;

  function onHide() {
    $error = null;
  }

  import Icon from "mdi-svelte";
  import { mdiAlertCircle } from "@mdi/js";
</script>

{#if $error}
  <div class="modal" class:is-active="{$error ? true : false}">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{$error.title}</p>
        <button
          class="delete"
          aria-label="close"
          on:click|preventDefault="{onHide}"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="media">
          <div class="media-left">
            <span class="icon is-large has-text-danger">
              <i class="mdi mdi-48px">
                <Icon path="{mdiAlertCircle}" size="48px" />
              </i>
            </span>
          </div>
          <div class="media-content">{$error.message}</div>
        </div>
      </section>
    </div>
  </div>
{/if}
