import * as z from './base';
export class ZodRecord extends z.ZodType {
    constructor() {
        super(...arguments);
        this.toJSON = () => ({
            t: this._def.t,
            valueType: this._def.valueType.toJSON(),
        });
    }
}
// opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
// null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
ZodRecord.create = (valueType) => {
    return new ZodRecord({
        t: z.ZodTypes.record,
        valueType,
    });
};
