import RequestBuilder from "App/api/RequestBuilder";
import {
  ApiError,
  UnauthorizedApiError,
  ApiResponseValidationError,
} from "App/api/ApiErrors";

export default class BaseApi {
  _requestBuilder() {
    return new RequestBuilder("/api/v0");
  }

  _logBefore(obj) {
    console.log("-->", obj);
  }

  _logAfter(obj) {
    console.log("<--", obj);
  }

  _raise(ex) {
    throw ex;
  }

  async _execute({ url, request, log_context }) {
    this._logBefore({ url, request, log_context });
    const response = await fetch(url, request);
    this._logAfter({
      url,
      request,
      response,
      log_context,
    });
    return response;
  }

  _validateJson(json, schema) {
    const result = schema.safeParse(json);
    if (result.success) {
      return result.data;
    } else {
      this._raise(new ApiResponseValidationError(result.error));
    }
  }

  async _executeExpectJson(req, schema) {
    const response = await this._execute(req);
    if (response.ok) {
      let json = await response.json();
      if (schema) {
        return this._validateJson(json, schema);
      }
      return json;
    } else if (response.status === 401) {
      this._raise(new UnauthorizedApiError());
    } else {
      this._raise(
        new ApiError(
          "HTTP Error. Expected 20x, got: " + response.status.toString()
        )
      );
    }
  }

  async _executeExpectOk(req) {
    const response = await this._execute(req);
    if (response.ok) {
      return;
    } else if (response.status === 401) {
      this._raise(new UnauthorizedApiError());
    } else {
      this._raise(
        new ApiError(
          "HTTP Error. Expected 20x, got: " + response.status.toString()
        )
      );
    }
  }
}
