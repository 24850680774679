<script>
  import Toggle from "App/components/Toggle";
  import { navigate } from "svelte-routing";
  import TradingApi from "App/api/TradingApi";
  import TabularData from "App/components/TabularData";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import ErrorDialog from "App/components/ui/ErrorDialog";
  import formatTradingThreshold from "App/utils/formatTradingThreshold";
  import { writable } from "svelte/store";

  export let traderConfig;
  export let reload;

  async function removeConfiguration() {
    await TradingApi()
      .deleteTraderConfiguration(traderConfig.id)
      .then(() => reload())
      .catch(ApiErrorHandler);
  }

  async function enableTraderConfiguration(traderConfigurationId) {
    return await enableOrDisableTraderConfiguration(
      traderConfigurationId,
      true
    );
  }

  async function disableTraderConfiguration(traderConfigurationId) {
    return await enableOrDisableTraderConfiguration(
      traderConfigurationId,
      false
    );
  }

  let error = writable(null);

  function showError(title, message) {
    $error = {
      title,
      message,
    };
  }

  async function enableOrDisableTraderConfiguration(
    traderConfigurationId,
    enable
  ) {
    try {
      let { ok, reason } = await (enable
        ? TradingApi().enableTraderConfiguration(traderConfigurationId)
        : TradingApi().disableTraderConfiguration(traderConfigurationId));
      if (!ok)
        showError(
          "Failed to " +
            (enable ? "enable" : "disable") +
            " trader configuration: " +
            traderConfigurationId,
          reason
        );
      reload();
      return ok;
    } catch (ex) {
      ApiErrorHandler(ex);
      return false;
    }
  }

  function format_api_key_str(api_key) {
    return [
      api_key.exchange ? "[" + api_key.exchange + "]" : null,
      formatApiKey(api_key.api_key),
      api_key.comment ? "(" + api_key.comment + ")" : null,
    ]
      .filter((e) => e)
      .join(" ");
  }

  function formatApiKey(api_key) {
    return api_key.substring(0, 16);
  }

  async function onToggle(newState) {
    if (newState === true) {
      return await enableTraderConfiguration(traderConfig.id);
    } else {
      return await disableTraderConfiguration(traderConfig.id);
    }
  }

  $: rows = [
    ["Enabled", traderConfig.enabled ? "ON" : "OFF"],
    [
      "API Key",
      traderConfig.api_key_info
        ? format_api_key_str(traderConfig.api_key_info)
        : "None",
    ],
    ["Comment", traderConfig.comment || ""],
    [
      "Trading Threshold",
      formatTradingThreshold(traderConfig.trading_threshold_permille),
    ],
    ["Coin Ratios", JSON.stringify(traderConfig.coin_ratios)],
  ];
</script>

<ErrorDialog error="{error}" />

<div class="card mt-6">
  <header class="card-header">
    <p class="card-header-title">
      {traderConfig.trader_name} #{traderConfig.id}
    </p>
  </header>
  <div class="card-content">
    {#if traderConfig.comment != ''}
      <p class="subtitle">{traderConfig.comment}</p>
    {/if}
    {#if traderConfig.trader_description}
      <p class="subtitle">{traderConfig.trader_description}</p>
    {/if}

    <Toggle
      id="toggle_trader_configuration_{traderConfig.id}"
      initialState="{traderConfig.enabled}"
      onToggle="{onToggle}"
    />

    <TabularData
      numColumns="{2}"
      title="Settings"
      rows="{rows}"
      isBordered="{false}"
    />
  </div>
  <footer class="card-footer">
    <!-- svelte-ignore a11y-missing-attribute -->
    <a class="card-footer-item" on:click|preventDefault="{removeConfiguration}">
      Delete
    </a>
    <a
      href="/account/trader_configurations/{traderConfig.id}/edit"
      class="card-footer-item"
    >
      Edit
    </a>
  </footer>
</div>
