<script>
  import { useQuery } from "@sveltestack/svelte-query";
  import { getTraderConfigurations } from "App/api/DashboardApi";
  import TraderWidget from "./TraderWidget.svelte";

  const queryResult = useQuery(
    "dashboard/traderConfigurations",
    getTraderConfigurations
  );
</script>

{#if $queryResult.isLoading}
  <span>Loading...</span>
{:else if $queryResult.isError}
  <span>Error: {$queryResult.error.message}</span>
{:else}
  {#each $queryResult.data as config (config.id)}
    <TraderWidget
      traderConfigId="{config.id}"
      traderName="{config.trader_name}"
    />
  {/each}
{/if}
