<script>
  export let profile = null;
  import ContentPage from "./ContentPage.svelte";
  import PrivacyPolicyContent from "./Content/PrivacyPolicyContent.svelte";
</script>

<ContentPage
  profile="{profile}"
  title="Privacy Notice"
  content="{PrivacyPolicyContent}"
/>
