import BaseApi from "App/api/BaseApi";
import GetProfileResponse from "App/schemas/api/GetProfileResponse";
import OkResponse from "App/schemas/api/OkResponse";

export class ProfileApi extends BaseApi {
  async getProfile() {
    const req = this._requestBuilder()
      .logContext("getProfile", undefined)
      .GET()
      .withSession()
      .path(["profile"])
      .build();

    return await this._executeExpectJson(req, GetProfileResponse);
  }

  async disableTwoFactorAuth() {
    return await configTwoFactorAuth(null);
  }

  async configTwoFactorAuth(secret) {
    const req = this._requestBuilder()
      .logContext("configTwoFactorAuth", secret)
      .POST()
      .withSession()
      .path(["2fa", "config"])
      .jsonBody({ secret })
      .build();

    const { ok } = await this._executeExpectJson(req, OkResponse);
    return ok;
  }
}

export default () => new ProfileApi();
