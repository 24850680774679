<script>
  import SessionApi from "App/api/SessionApi";
  import Login2Model from "App/models/Login2Model";
  import ModelForm from "App/components/ModelForm";

  export let loginFailed;
  export let next;
  export let twofactor_check_token;

  let isLoading = false;
  let model = Login2Model();

  async function onSubmitValid({ code }) {
    if (isLoading) {
      console.log("Login2 in progress. Aborting");
      return;
    }
    isLoading = true;
    loginFailed.set(null);

    try {
      let response = await SessionApi().twofactor_check(
        twofactor_check_token,
        code
      );

      if (response.type === "InvalidCode") {
        model.errors = { code: "Invalid code" };
      } else if (response.type === "Granted" && response.token) {
        next({ twofactor_token: response.token });
      } else {
        throw "Invalid response";
      }
    } catch (ex) {
      loginFailed.set(`Login Failed: ${ex.error}`);
    }
    isLoading = false;
  }
</script>

<ModelForm
  model="{model}"
  onSubmitValid="{onSubmitValid}"
  disabled="{isLoading}"
>
  <div class="field">
    <button
      type="submit"
      class:is-loading="{isLoading}"
      class="button is-block is-danger is-fullwidth"
    >
      Enter 2FA Code
    </button>
  </div>
</ModelForm>
