import * as z from "../../../vendor/zod/src/index";
import Currency from "./Currency";
const AssetEntry = z.object({
    currency: Currency,
    amount: z.string().regex(/^\d+([.]\d+)?$/),
});
export default z.object({
    validity: z.string().nullable(),
    assets: z.array(AssetEntry),
});
