<script>
  export let field = "password";
  export let placeholder = "Password";
  export let model;
  export let disabled;
  $: error = model.errorFor(field);
</script>

<input
  type="password"
  required
  class="input"
  class:is-danger="{error}"
  bind:value="{model.values[field]}"
  placeholder="{placeholder}"
  disabled="{disabled}"
/>
