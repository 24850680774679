const SupportedExchanges = [
  { id: "bitfinex2", name: "Bitfinex (API V2)" },
  { id: "bitfinex", name: "Bitfinex (API V1)" },
  { id: "bitstamp", name: "Bitstamp (API V2)" },
  { id: "bitstamp1", name: "Bitstamp (API V1)" },
  { id: "binance", name: "Binance" },
  { id: "kraken", name: "Kraken" },
  { id: "vaultoro", name: "Vaultoro" },
];

export default SupportedExchanges;
