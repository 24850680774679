<script>
  export let menuOpen = false;

  function toggleMenu() {
    menuOpen = !menuOpen;
  }
</script>

<nav
  class="navbar is-spaced has-shadow"
  role="navigation"
  aria-label="main navigation"
>
  <div class="container">
    <div class="navbar-brand">
      <a href="/" class="navbar-item mr-6">
        <img src="/img/logo.png" height="36" alt="Aizu Logo" />
      </a>

      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        role="button"
        class:is-active="{menuOpen}"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="{menuOpen ? 'true' : 'false'}"
        on:click|preventDefault="{toggleMenu}"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" class:is-active="{menuOpen}">
      <slot />
    </div>
  </div>
</nav>
