<script>
  // XXX: Unify with RegisterPage.svelte
  import UnauthApi from "App/api/UnauthApi";
  import CaptchaForm from "App/components/CaptchaForm";
  import ResetPasswordModel from "App/models/ResetPasswordModel";
  import FullscreenDialog from "App/components/FullscreenDialog";
  import SeparatedLinks from "App/components/SeparatedLinks";
  import ModelForm from "App/components/ModelForm";
  import AgreeTerms from "App/components/AgreeTerms";

  let state = { type: "init" };
  let model = ResetPasswordModel();

  function onSubmitValid() {
    state = { type: "challenge" };
  }

  async function onGranted(grantToken) {
    state = { type: "sending" };

    await submitRequest(grantToken, {
      email: model.valueFor("email"),
    });
  }

  function retry() {
    state = { type: "init" };
  }

  async function submitRequest(grantToken, request) {
    try {
      await UnauthApi().resetPassword(grantToken, request);
      state = { type: "success" };
    } catch (ex) {
      state = { type: "error", error: ex.error };
    }
  }
</script>

<FullscreenDialog title="Reset your Password">
  {#if state.type == 'init'}
    <ModelForm
      model="{model}"
      onSubmitValid="{onSubmitValid}"
      withSubmitButton="Reset Password"
    >
      <AgreeTerms by_doing="resetting your password" />
    </ModelForm>
  {/if}

  {#if state.type == 'challenge'}
    <p class="subtitle">
      Please solve a captcha before we can send you a reset email...
    </p>
    <CaptchaForm onGranted="{onGranted}" />
  {/if}

  {#if state.type === 'sending'}
    <p class="subtitle">We are sending your request...</p>
    <progress class="progress is-medium is-light" max="100">
      Sending your request
    </progress>
  {/if}

  {#if state.type === 'error'}
    <div class="notification is-danger">
      <button on:click="{retry}" class="delete">Retry</button>
      <p class="subtitle">Something went wrong!</p>
      <p></p>
      <p>{state.error}</p>
    </div>
  {/if}

  {#if state.type === 'success'}
    <p class="subtitle">Reset Password request was successful!</p>
    <p>
      Please wait for an email. If you do not get an email from us, your
      provided email might not exist in our database.
    </p>
  {/if}

  <p slot="below">
    <SeparatedLinks
      links="{[{ href: '/account/login', title: 'Log in' }, { href: '/help', title: 'Need Help?' }]}"
    />
  </p>
</FullscreenDialog>
