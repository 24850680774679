<script>
  import OriginModel from "App/models/verification/OriginModel";
  import ModelForm from "App/components/ModelForm";
  import VerificationApi from "App/api/VerificationApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import StepsProgress from "./StepsProgress.svelte";

  export let onError;
  export let onSuccess;

  let model = OriginModel();

  async function submitOrigin(address) {
    try {
      let { ok, error } = await VerificationApi().saveAddress(address);
      if (ok) {
        onSuccess();
      } else {
        onError(error);
      }
    } catch (ex) {
      ApiErrorHandler(ex);
    }
  }
</script>

<StepsProgress activeStep="origin" />

<p class="title">Where are you from?</p>
<ModelForm
  onSubmitValid="{submitOrigin}"
  model="{model}"
  withSubmitButton="Next"
/>
