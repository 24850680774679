import { defineModelForFields } from "App/model";
import countries from "App/data/country-list.json";

function mapCountryToOption(country) {
  return {
    id: country.code,
    text: country.name,
  };
}

const countryOptions = [{ id: "", text: "Select country" }].concat(
  countries.map(mapCountryToOption)
);

const fields = [
  {
    name: "country",
    type: "select",
    label: "Country",
    defaultValue: () => "",
    options: countryOptions,
    validate: {
      presence: { allowEmpty: false },
    },
  },
  {
    name: "street_address",
    type: "text",
    label: "Street address",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: false },
    },
  },
  {
    name: "city",
    type: "text",
    label: "City",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: false },
    },
  },
  {
    name: "postal_code",
    type: "text",
    label: "Postal code / ZIP",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: false },
    },
  },
  {
    name: "has_nationality_from_country",
    type: "checkbox",
    label: "I hold nationality from above country",
    defaultValue: () => false,
    validate: {
      type: "boolean",
    },
  },
];

export default defineModelForFields(fields);
