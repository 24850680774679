<script>
  export let label;
  export let error;
  export let showIcon = true;

  import Icon from "mdi-svelte";
  import { mdiAlertCircle } from "@mdi/js";
</script>

<div class="field">
  {#if label}
    <div class="label has-text-left">{label}</div>
  {/if}
  <div class:has-icons-right="{error && showIcon}" class="control">
    <slot />
    {#if error && showIcon}
      <span class="icon is-small is-right">
        <i class="mdi">
          <Icon path="{mdiAlertCircle}" />
        </i>
      </span>
    {/if}
  </div>
  {#if error}
    <p class="help is-danger">{error}</p>
  {/if}
</div>
