<script>
  export let profile = null;
  import ContentPage from "./ContentPage.svelte";
  import TermsContent from "./Content/TermsContent.svelte";
</script>

<ContentPage
  profile="{profile}"
  title="Aizu Limited Terms of Service"
  content="{TermsContent}"
/>
