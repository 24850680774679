<script>
  export let title = null;
  export let showLogo = true;
</script>

<section class="hero is-fullheight is-light">
  <div class="hero-body">
    <div class="container has-text-centered">
      <div class="column is-4 is-offset-4">
        {#if showLogo}
          <div style="padding: 0 0 1em 0;">
            <figure class="image container is-64x64">
              <a href="/"> <img src="/img/mark.png" alt="Aizu Logo" /> </a>
            </figure>
          </div>
        {/if}

        {#if title}
          <h1 class="title has-text-dark">{title}</h1>
        {/if}
        <div class="box" style="padding: 2em;">
          <slot />
        </div>
        <slot name="below" />
      </div>
    </div>
  </div>
</section>
