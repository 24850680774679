import BaseApi from "App/api/BaseApi";
import CreateIssueResponse from "App/schemas/api/CreateIssueResponse";

export class IssuesApi extends BaseApi {
  async createIssue(
    {
      reporter_name,
      reporter_email,
      reporter_phone,
      issue_subject,
      issue_description,
    },
    grantToken
  ) {
    const request = {
      reporter_name,
      reporter_email,
      reporter_phone,
      issue_subject,
      issue_description,
    };
    const query = grantToken ? { token: grantToken } : {};
    const req = this._requestBuilder()
      .logContext(this.createIssue.name, request)
      .POST()
      .withSession() // optional
      .jsonBody(request)
      .path(["issues"])
      .query(query)
      .build();

    let { issue_id } = await this._executeExpectJson(req, CreateIssueResponse);
    return issue_id;
  }
}

export default () => new IssuesApi();
