<script>
  import SessionApi from "App/api/SessionApi";
  import ChangePasswordModel from "../models/ChangePasswordModel";
  import FullscreenDialog from "./FullscreenDialog.svelte";
  import SeparatedLinks from "./SeparatedLinks.svelte";
  import ModelForm from "App/components/ModelForm";

  export let title = "Change Password";

  function initialState() {
    return { type: "form", model: ChangePasswordModel() };
  }

  function retry() {
    state = initialState();
  }

  let state = initialState();

  async function onSubmitValid({ password }) {
    await SessionApi()
      .changePassword(password)
      .then(() => {
        state = { type: "success" };
      })
      .catch((err) => {
        state = { type: "failed", message: "Failed to set password" };
      });
  }
</script>

<FullscreenDialog title="{title}">
  {#if state.type === 'form'}
    <p class="subtitle">Let's set your password</p>
    <ModelForm
      model="{state.model}"
      onSubmitValid="{onSubmitValid}"
      withSubmitButton="Continue"
    />
  {/if}
  {#if state.type === 'success'}
    <div class="notification is-success">
      Your password has been set successfully. Please <a
        href="/account/relogin"
      >Login</a> with your new password now.
    </div>
    <a href="/account/relogin" class="button is-block is-fullwidth">Login</a>
  {/if}
  {#if state.type === 'failed'}
    <div class="notification is-danger">
      <p class="subtitle">Failed to set your new password.</p>
    </div>
    <button class="button is-block is-fullwidth" on:click="{retry}">
      Retry
    </button>
  {/if}

  <p slot="below">
    <SeparatedLinks links="{[{ href: '/help', title: 'Need Help?' }]}" />
  </p>
</FullscreenDialog>
