<script>
  export let profile;

  import Card from "App/components/ui/Card";
  import SetupTwoFactor from "App/components/Config/SetupTwoFactor";
  import UnconfigureTwoFactor from "App/components/Config/UnconfigureTwoFactor";
  import { session } from "App/stores/session";
  import { derived } from "svelte/store";

  let is_configured = derived(session, ($s) => $s.profile.is_account_secured);

  function updated() {
    session.fetchProfile();
  }
</script>

<Card title="Secure your Account">
  {#if $is_configured}
    <UnconfigureTwoFactor updated="{updated}" />
  {:else}
    <SetupTwoFactor email="{profile.email}" updated="{updated}" />
  {/if}
</Card>
