import { defineModelForFields } from "App/model";

function makeFields(termsRenderComponent) {
  return [
    {
      name: "first_name",
      label: "First name",
      type: "text",
      defaultValue: () => "",
      validate: {
        presence: { allowEmpty: false },
      },
    },
    {
      name: "last_name",
      label: "Last name",
      type: "text",
      defaultValue: () => "",
      validate: {
        presence: { allowEmpty: false },
      },
    },
    {
      name: "email",
      type: "email",
      defaultValue: () => "",
      validate: {
        email: true,
      },
    },
    {
      name: "tos_agree",
      type: "checkbox",
      label: null,
      labelComponent: termsRenderComponent,
      defaultValue: () => false,
      validate: {
        type: "boolean",
        presence: true,
        inclusion: {
          within: [true],
          message: "^You must agree to our ToS and Privacy Policy",
        },
      },
    },
  ];
}

export default (termsRenderComponent) =>
  defineModelForFields(makeFields(termsRenderComponent));
