<script>
  import profile from "App/stores/profile";

  import NavBar from "App/components/ui/NavBar";
  import NavItemLink from "App/components/ui/NavItemLink";

  import ProfileDropDown from "App/components/ProfileDropDown";
  import FetchProfileOnce from "App/components/FetchProfileOnce";
  import VerificationWarning from "App/components/VerificationWarning";

  export let component;
  export let showVerificationWarning = true;
  export let params = {};
</script>

<FetchProfileOnce onErrRedirect="/account/login" />

{#if $profile}
  <NavBar>
    <div class="navbar-start">
      <NavItemLink to="/account/dashboard">Dashboard</NavItemLink>
      <NavItemLink to="/account/trader_configurations">
        Trader Configuration
      </NavItemLink>
      <NavItemLink to="/account/api_keys">API Keys</NavItemLink>
    </div>
    <div class="navbar-end">
      <ProfileDropDown profile="{$profile}" />
    </div>
  </NavBar>

  <main class="hero is-fullheight is-light is-paddingless">
    <div class="hero-head">
      <div class="container">
        {#if showVerificationWarning}
          <VerificationWarning />
        {/if}
        <svelte:component this="{component}" profile="{$profile}" {...params} />
      </div>
    </div>
    <div class="hero-foot">
      <div class="mt-6"></div>
    </div>
  </main>
{/if}
