import { defineModelForFields } from "App/model";

const fields = [
  {
    name: "code",
    type: "text",
    label: "2FA code",
    placeholder: "Enter 2FA code",
    length: 6,
    defaultValue: () => "",
    validate: {
      numericality: true,
      length: { minimum: 6, maximum: 6 },
    },
  },
];

export default defineModelForFields(fields);
