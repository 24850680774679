<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import api_keys from "App/stores/api_keys";
  import traders from "App/stores/traders";
  import TradingApi from "App/api/TradingApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import EditForm from "App/components/TraderConfigurations/EditForm";

  export let trader_configuration_id;

  let traderConfig;
  let traderInfo;

  async function load() {
    await api_keys.load(); // XXX: Cache it!
    await traders.load();

    traderConfig = await TradingApi().getTraderConfiguration(
      trader_configuration_id
    );

    traderInfo = $traders.find((t) => t.id === traderConfig.trader_id);
  }

  onMount(async () => {
    await load().catch(ApiErrorHandler);
  });

  function navigateToShow() {
    navigate(`/account/trader_configurations`, { replace: true });
  }

  function onCancel() {
    navigateToShow();
  }

  async function doSubmitValid({
    api_key,
    comment,
    trading_threshold_permille,
    coin_ratios,
  }) {
    await TradingApi().updateTraderConfiguration(trader_configuration_id, {
      api_key: api_key || null,
      options: {},
      comment: comment || "",
      trading_threshold_permille,
      coin_ratios,
    });
    navigateToShow();
  }

  async function onSubmitValid({
    api_key,
    comment,
    trading_threshold,
    coin_ratios,
  }) {
    await doSubmitValid({
      api_key,
      comment,
      trading_threshold_permille: trading_threshold,
      coin_ratios,
    }).catch(ApiErrorHandler);
  }
</script>

{#if traderConfig && traderInfo}
  <EditForm
    traderConfig="{traderConfig}"
    traderInfo="{traderInfo}"
    apiKeys="{$api_keys}"
    onCancel="{onCancel}"
    onSubmitValid="{onSubmitValid}"
  />
{:else}Loading...{/if}
