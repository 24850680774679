import * as z from './base';
export class ZodUnion extends z.ZodType {
    constructor() {
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        super(...arguments);
        // null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.toJSON = () => ({
            t: this._def.t,
            options: this._def.options.map(x => x.toJSON()),
        });
    }
}
// distribute = <F extends (arg: T[number]) => z.ZodTypeAny>(f: F): ZodUnion<{ [k in keyof T]: ReturnType<F> }> => {
//   return ZodUnion.create(this._def.options.map(f) as any);
// };
ZodUnion.create = (types) => {
    return new ZodUnion({
        t: z.ZodTypes.union,
        options: types,
    });
};
