<script>
  import InputFieldContainer from "App/components/ui/InputFieldContainer";
  import EmailInputField from "App/components/ui/EmailInputField";
  import PasswordInputField from "App/components/ui/PasswordInputField";
  import CheckboxFieldWithContainer from "App/components/ui/CheckboxFieldWithContainer";
  import TextInputFieldWithContainer from "App/components/ui/TextInputFieldWithContainer";
  import TextareaInputField from "App/components/ui/TextareaInputField";
  import MultiRatioSlider from "App/components/ui/MultiRatioSlider";

  export let model;
  export let onSubmitValid;
  export let disabled = false;
  export let withSubmitButton = null;

  function onSubmit() {
    (model = model.validate()).ifValid(onSubmitValid);
  }
</script>

<form on:submit|preventDefault="{onSubmit}" novalidate>
  <slot name="fields">
    {#each model.fields as field}
      {#if field.type === 'email'}
        <InputFieldContainer
          label="{field.label || 'Email address'}"
          error="{model.errorFor(field.name)}"
        >
          <EmailInputField
            field="{field.name}"
            model="{model}"
            disabled="{disabled}"
          />
        </InputFieldContainer>
      {/if}
      {#if field.type === 'password'}
        <InputFieldContainer
          label="{field.label || 'Password'}"
          error="{model.errorFor(field.name)}"
        >
          <PasswordInputField
            field="{field.name}"
            model="{model}"
            disabled="{disabled}"
            placeholder="{field.label || 'Password'}"
          />
        </InputFieldContainer>
      {/if}
      {#if field.type === 'text'}
        <TextInputFieldWithContainer
          field="{field.name}"
          label="{field.label}"
          placeholder="{field.placeholder || ''}"
          model="{model}"
        />
      {/if}
      {#if field.type === 'textarea'}
        <InputFieldContainer
          label="{field.label}"
          error="{model.errorFor(field.name)}"
        >
          <TextareaInputField
            field="{field.name}"
            placeholder="{field.placeholder || ''}"
            model="{model}"
          />
        </InputFieldContainer>
      {/if}

      {#if field.type === 'checkbox'}
        <CheckboxFieldWithContainer
          field="{field.name}"
          label="{field.label}"
          labelComponent="{field.labelComponent}"
          model="{model}"
        />
      {/if}
      {#if field.type === 'range'}
        <InputFieldContainer
          label="{field.label}"
          error="{model.errorFor(field.name)}"
        >
          <input
            class="slider has-output"
            min="{field.min || 0}"
            max="{field.max || 100}"
            bind:value="{model.values[field.name]}"
            step="{field.step || 1}"
            type="range"
          />
          <span class="tag is-light">
            {field.valueFormatter(model.values[field.name])}
          </span>
        </InputFieldContainer>
      {/if}

      {#if field.type === 'multi-ratio-slider'}
        <InputFieldContainer
          label="{field.label}"
          error="{model.errorFor(field.name)}"
          showIcon="{false}"
        >
          <MultiRatioSlider field="{field}" model="{model}" />
        </InputFieldContainer>
      {/if}

      {#if field.type === 'select'}
        <InputFieldContainer
          label="{field.label}"
          error="{model.errorFor(field.name)}"
        >
          <div class="select">
            <select
              aria-label="{field.label}"
              bind:value="{model.values[field.name]}"
            >
              {#each field.options as option}
                <option value="{option.id}">{option.text}</option>
              {/each}
            </select>
          </div>
        </InputFieldContainer>
      {/if}
    {/each}
  </slot>

  <slot name="buttons">
    {#if withSubmitButton}
      <div class="field">
        <button type="submit" class="button is-block is-danger is-fullwidth">
          {withSubmitButton}
        </button>
      </div>
    {/if}
  </slot>

  <slot />
</form>
