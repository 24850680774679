<script>
  export let profile = null;
  import ContentPage from "./ContentPage.svelte";
  import FaqContent from "./Content/FaqContent.svelte";
</script>

<ContentPage profile="{profile}" isWide="{true}" noContainer>
  <h1 class="title has-text-centered mb-6">Frequently Asked Questions</h1>
  <FaqContent />
</ContentPage>
