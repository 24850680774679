<script>
  import NavBarLoginButtons from "./NavBarLoginButtons.svelte";

  export let profile;

  let open = false;
</script>

<nav class="navbar is-spaced" role="navigation" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand">
      <a href="/" class="navbar-item">
        <img src="/img/logo.png" height="36" alt="Aizu Logo" />
      </a>

      <!-- svelte-ignore a11y-missing-attribute -->
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        class:is-active="{open}"
        on:click|preventDefault="{() => (open = !open)}"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" class:is-active="{open}">
      <div class="navbar-end">
        <div class="navbar-item">
          <NavBarLoginButtons profile="{profile}" />
        </div>
      </div>
    </div>
  </div>
</nav>
