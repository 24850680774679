<script>
  export let title = null;
  export let rows;
  export let headers = null;
  export let numColumns;
  export let isStriped = true;
  export let isHoverable = true;
  export let isFullwidth = true;
  export let isBordered = true;
  export let customStyle = "";
</script>

<table
  class="table"
  class:is-striped="{isStriped}"
  class:is-fullwidth="{isFullwidth}"
  class:is-bordered="{isBordered}"
  class:is-hoverable="{isHoverable}"
  style="{customStyle}"
>
  <slot name="title">
    {#if title}
      <thead>
        <tr>
          <th colspan="{numColumns}">{title}</th>
        </tr>
      </thead>
    {/if}
  </slot>
  {#if headers}
    <thead>
      <tr>
        {#each headers as header}
          <th>{header}</th>
        {/each}
      </tr>
    </thead>
  {/if}
  <tbody>
    {#each rows as row}
      <tr>
        {#each row as value}
          <td>{value}</td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>
