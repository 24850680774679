<script>
  import SessionApi from "App/api/SessionApi";
  import LoginModel from "App/models/LoginModel";
  import ModelForm from "App/components/ModelForm";
  import AgreeTerms from "App/components/AgreeTerms";

  export let loginFailed;
  export let next;

  let isLoading = false;
  let model = LoginModel();

  async function onSubmitValid({ email, password }) {
    if (isLoading) {
      console.log("Login in progress. Aborting");
      return;
    }
    isLoading = true;
    loginFailed.set(null);

    try {
      let {
        credential_token,
        twofactor_check_token,
      } = await SessionApi().login(email, password);
      next({ credential_token, twofactor_check_token });
    } catch (ex) {
      loginFailed.set(`Login Failed: ${ex.error}`);
    }
    isLoading = false;
  }
</script>

<ModelForm
  model="{model}"
  onSubmitValid="{onSubmitValid}"
  disabled="{isLoading}"
>
  <div class="field">
    <button
      type="submit"
      class:is-loading="{isLoading}"
      class="button is-block is-danger is-fullwidth"
    >
      Log in
    </button>
  </div>
  <AgreeTerms by_doing="signing in" />
</ModelForm>
