<script>
  export let profile;
  import Icon from "mdi-svelte";
  import { mdiAccount, mdiLogout } from "@mdi/js";
</script>

<div class="navbar-item has-dropdown is-hoverable">
  <a href="/account/dashboard" class="navbar-link button is-outlined">
    <span class="icon">
      <i class="mdi">
        <Icon path="{mdiAccount}" />
      </i>
    </span>
    <span>{profile.email}</span>
  </a>

  <div class="navbar-dropdown">
    <a class="navbar-item" href="/account/profile">
      {profile.first_name}
      {profile.last_name} (Profile)
    </a>
    <a class="navbar-item" href="/account/dashboard">Dashboard</a>
    <hr class="navbar-divider" />
    <a class="navbar-item" href="/account/config/secure">Security Settings</a>
    {#if !profile.is_verified}
      <a class="navbar-item" href="/account/verification">
        Account Verification
      </a>
    {/if}
    <a class="navbar-item" href="/account/password/change">Change Password</a>
    <hr class="navbar-divider" />
    <a class="navbar-item" href="/account/logout">
      <span class="icon mr-1">
        <i class="mdi">
          <Icon path="{mdiLogout}" />
        </i>
      </span>
      <span>Log out</span>
    </a>
  </div>
</div>
