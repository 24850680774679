<script>
  export let id;
  export let initialState;
  export let onToggle = null;

  let in_progress = false;

  let currentState = initialState;
  let previousState = initialState;

  $: if (currentState != previousState) {
    onChange();
  }

  async function onChange() {
    if (!in_progress) {
      in_progress = true;

      let ok = true;
      if (onToggle) {
        try {
          ok = await onToggle(currentState);
        } catch (e) {
          console.log(e);
          ok = false;
        }
      }

      if (ok) {
        previousState = currentState;
      } else {
        currentState = previousState;
      }

      in_progress = false;
    }
  }
</script>

<div class="field">
  <input
    id="{id}"
    type="checkbox"
    class="switch is-rounded"
    disabled="{in_progress}"
    bind:checked="{currentState}"
  />
  <label for="{id}">Enable / Disable Trader</label>
</div>
