<script>
  export let email;
  export let updated;

  import QR from "App/components/ui/QR";
  import { session } from "App/stores/session";
  import ProfileApi from "App/api/ProfileApi";
  import authenticator from "App/utils/authenticator";
  import { errors } from "App/stores/errors";
  import Icon from "mdi-svelte";
  import { mdiLockOpenAlert } from "@mdi/js";

  const secret = authenticator.generateSecret(64); // only 20 needed
  const otpAuth = authenticator.keyuri(email, "Aizu.uk", secret);

  let enteredToken = "";
  let tokenCorrect = false;

  $: if (!tokenCorrect && enteredToken === authenticator.generate(secret)) {
    tokenCorrect = true;
  }

  async function activate() {
    const ok = await ProfileApi()
      .configTwoFactorAuth(secret)
      .catch((_e) => false);
    if (ok) {
      updated();
    } else {
      errors.add("Failed to setup 2FA");
    }
  }
</script>

<div class="media">
  <div class="media-left">
    <span class="icon is-large has-text-danger">
      <i class="mdi mdi-48px" aria-hidden="true">
        <Icon path="{mdiLockOpenAlert}" size="48px" />
      </i>
    </span>
  </div>
  <div class="media-content">
    <p>
      Your account is <strong class="has-text-danger">NOT secured</strong> with 2-factor
      authentication (2FA).
    </p>
    <p>To secure, please follow the procedure below:</p>
  </div>
</div>

<p class="mt-6">
  1. Scan in below QR code in your authenticator app.
  <QR value="{otpAuth}" />
</p>

<p>
  2. Please enter the token from the authenticator app to verify that everything
  works:
</p>
<form class="mt-4 ml-3" on:submit|preventDefault="{() => ''}">
  {#if !tokenCorrect}
    <div class="field">
      <div class="control">
        <input
          type="text"
          required
          class="input is-large"
          bind:value="{enteredToken}"
          placeholder="Enter token here"
          style="width: 10em;"
        />
      </div>
    </div>
  {:else}
    <div class="field">
      <div class="control">
        <label class="label">Token was correct</label>
        <button class="button is-primary" on:click|preventDefault="{activate}">
          Now activate 2FA
        </button>
      </div>
    </div>
  {/if}
</form>
