import {
  ApiError,
  ApiResponseValidationError,
  UnauthorizedApiError,
} from "App/api/ApiErrors";
import { session } from "App/stores/session";
import { sessionFetchState } from "App/stores/sessionFetchState";
import { errors } from "App/stores/errors";

export default function ApiErrorHandler(ex, handlerFn, showSessionError) {
  const handler = handlerFn || (() => null);

  if (ex instanceof UnauthorizedApiError) {
    if (showSessionError !== "ignore") {
      errors.add(ex.message);
    }
    session.set({});
    sessionFetchState.set({ type: sessionFetchState.FAILED });
  } else if (ex instanceof ApiError) {
    errors.add(ex.message);
    handler(ex);
  } else {
    errors.add(ex.message);
    handler(ex);
  }
}
