import * as z from "../../../vendor/zod/src/index";
const ApiKey = z.object({
    exchange: z.string(),
    api_key: z.string(),
    comment: z.string(),
    references: z.number().int(),
    customer_id: z.string(),
});
const ApiKeys = z.array(ApiKey);
export default z.object({ api_keys: ApiKeys });
