import { defineModelForFields } from "App/model";

const fields = [
  {
    name: "solution",
    type: "text",
    label: "Solution",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: false },
    },
  },
];

export default defineModelForFields(fields);
