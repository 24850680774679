<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import UnauthApi from "App/api/UnauthApi";
  import SessionApi from "App/api/SessionApi";
  import FullscreenDialog from "./FullscreenDialog.svelte";
  import SeparatedLinks from "./SeparatedLinks.svelte";

  export let confirmationToken;

  let state = { type: "in-progress" };

  onMount(async () => {
    state = { type: "in-progress" };
    try {
      const response = await UnauthApi().confirmRegistration(confirmationToken);
      // XXX: store locally
      await SessionApi().persist(response.session_token);
      navigate("/account/password/choose", { replace: true });
    } catch (ex) {
      console.log("Exception", ex);
      state = {
        type: "failed",
        error: "Failed to confirm your registration: " + ex.error,
      };
    }
  });
</script>

<FullscreenDialog title="Account confirmation">
  {#if state.type === 'in-progress'}
    <p class="subtitle">Confirmation in progress</p>
    <progress class="progress is-medium is-light" max="100">
      Confirmation in progress
    </progress>
  {/if}
  {#if state.type === 'failed'}
    <p class="subtitle notification is-danger">
      Invalid confirmation or confirmation token timed out. Please sign up a new
      account again.
    </p>
  {/if}
  {#if state.type === 'success'}
    <div class="notification is-success">Your registration was successful.</div>
  {/if}

  <p slot="below">
    <SeparatedLinks
      links="{[{ href: '/account/register', title: 'Sign Up' }, { href: '/account/login', title: 'Log in' }, { href: '/help', title: 'Need Help?' }]}"
    />
  </p>
</FullscreenDialog>
