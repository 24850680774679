<script>
  import { useQuery } from "@sveltestack/svelte-query";
  import { getActions } from "App/api/DashboardApi";
  import TabularData from "App/components/TabularData";
  import FetchButton from "./FetchButton.svelte";

  export let traderConfigId;
  export let limit = 1;
  export let showMore = true;

  const queryResult = useQuery(
    ["dashboard/actions", { id: traderConfigId }],
    getActions,
    { refetchInterval: 5 * 60 * 1000 }
  );
</script>

{#if $queryResult.isLoading}
  <span>Loading Actions...</span>
{:else if $queryResult.isError}
  <span>Error: {$queryResult.error.message}</span>
{:else}
  <div class="buttons is-right" style="margin-top: 1rem; margin-bottom: 0px">
    <FetchButton
      fetchDate="{$queryResult.data.fetchDate}"
      refetchQueries="{['dashboard/actions']}"
    />
  </div>
  {#if $queryResult.data.isEmpty}
    <div class="box is-marginless">
      <p class="is-center">No actions</p>
    </div>
  {:else}
    <div class="box is-paddingless is-marginless">
      <TabularData
        numColumns="{2}"
        headers="{['Date', 'Action']}"
        rows="{$queryResult.data.actions}"
        isBordered="{false}"
        isStriped="{false}"
        customStyle="border-radius: 5px;"
      >
        <thead slot="title">
          <tr>
            <th colspan="2">
              Trader actions (Limit = {limit}
              {#if showMore}
                , <a href="{`/account/trader_configurations/${traderConfigId}/actions`}"> more </a>
              {/if} )
            </th>
          </tr>
        </thead>
      </TabularData>
    </div>
  {/if}
{/if}
