<script>
  import { navigate } from "svelte-routing";
  import SessionApi from "App/api/SessionApi";
  import { session } from "App/stores/session";
  import LogoutDialog from "App/components/LogoutDialog";
  import { errors } from "App/stores/errors";

  let state = { type: "confirm" };

  function onCancel() {
    // XXX: go back instead?
    navigate("/account/dashboard", { replace: true });
  }

  async function onAction() {
    state = { type: "in-progress" };

    try {
      // reset session store in any case
      session.reset();

      await SessionApi().logout();
      state = { type: "ok" };
      setTimeout(() => {
        window.location.replace("/");
      }, 1000);
    } catch (ex) {
      errors.add("Failed to log out: " + ex.error);
      state = { type: "confirm" };
    }
  }
</script>

{#if state.type === 'confirm'}
  <LogoutDialog show="{true}" onCancel="{onCancel}" onAction="{onAction}" />
{/if}
{#if state.type === 'in-progress'}
  <div class="notification is-warning">You are getting logged out...</div>
{/if}
{#if state.type === 'ok'}
  <div class="notification is-success">
    You are successfully logged out. <a href="/">Goto home</a>
  </div>
{/if}
