export default function formatCurrencyNumber(number, currency) {
  switch (currency) {
    case "USD":
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }).format(number);

    default:
      return number.toString();
  }
}
