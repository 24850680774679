import BaseApi from "App/api/BaseApi";
import SessionLoginResponse from "App/schemas/api/SessionLoginResponse";
import SessionLogin2Response from "App/schemas/api/SessionLogin2Response";
import TwoFactorCheckResponse from "App/schemas/api/TwoFactorCheckResponse";

export class SessionApi extends BaseApi {
  async login(email, password) {
    const req = this._requestBuilder()
      .logContext(this.login.name, {
        email,
        password,
      })
      .POST()
      .path("/session/login")
      .jsonBody({ email, password })
      .build();

    let obj = await this._executeExpectJson(req, SessionLoginResponse);
    return obj;
  }

  async twofactor_check(token, code) {
    const req = this._requestBuilder()
      .logContext(this.twofactor_check.name, {
        token,
        code,
      })
      .POST()
      .path("/2fa/check")
      .jsonBody({ token, code })
      .build();

    let obj = await this._executeExpectJson(req, TwoFactorCheckResponse);
    return obj;
  }

  async login2(credential_token, twofactor_token) {
    const req = this._requestBuilder()
      .logContext(this.login2.name, {
        credential_token,
        twofactor_token,
      })
      .POST()
      .path("/session/login2")
      .jsonBody({ credential_token, twofactor_token })
      .build();

    let obj = await this._executeExpectJson(req, SessionLogin2Response);
    return obj;
  }

  async persist(session_token) {
    const req = this._requestBuilder()
      .logContext(this.persist.name, {
        session_token,
      })
      .POST()
      .path("/session/persist")
      .jsonBody({ session_token })
      .build();

    await this._executeExpectOk(req);
  }

  async logout() {
    const req = this._requestBuilder()
      .logContext(this.logout.name, undefined)
      .POST()
      .withSession()
      .path("/session/logout")
      .noBody()
      .build();

    await this._executeExpectOk(req);
  }

  async changePassword(newPassword) {
    const req = await this._requestBuilder()
      .logContext(this.changePassword.name, { newPassword })
      .POST()
      .withSession()
      .path("/password/change")
      .jsonBody({ password: newPassword })
      .build();

    await this._executeExpectOk(req);
  }
}

export default () => new SessionApi();
