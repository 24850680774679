import { defineModelForFields } from "App/model";
import SupportedExchanges from "./SupportedExchanges";

function mapExchangeToOption(exchange) {
  return {
    id: exchange.id,
    text: exchange.name,
  };
}

const exchangeOptions = [{ id: "", text: "Select Exchange..." }].concat(
  SupportedExchanges.map(mapExchangeToOption)
);

const fields = [
  {
    name: "exchange",
    type: "select",
    label: "Exchange",
    defaultValue: () => "",
    options: exchangeOptions,
    validate: {
      presence: { allowEmpty: false },
    },
  },
  {
    name: "api_key",
    type: "text",
    label: "API Key",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: false },
      length: { minimum: 8, maximum: 6000 },
    },
  },
  {
    name: "api_secret",
    type: "text",
    label: "API Secret",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: true },
    },
  },
  {
    name: "customer_id",
    type: "text",
    label: "Customer ID (for Bitstamp)",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: true },
    },
  },
  {
    name: "comment",
    type: "text",
    label: "Comment (optional, for you to remember the key)",
    defaultValue: () => "",
    validate: {
      presence: { allowEmpty: true },
    },
  },
];

export default defineModelForFields(fields);
