export class ApiError extends Error {
  constructor(message) {
    super(message);
  }
}

export class ApiResponseValidationError extends ApiError {
  constructor(message) {
    super(message);
  }
}
export class UnauthorizedApiError extends ApiError {
  constructor() {
    super("Lost session");
  }
}
