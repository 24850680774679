import { defineModelForFields } from "App/model";

function makeFields(trader_options) {
  return [
    {
      name: "trader",
      type: "select",
      label: "Trader",
      defaultValue: () => "",
      options: trader_options,
      validate: {
        presence: { allowEmpty: false },
      },
    },
  ];
}

export default (trader_options) =>
  defineModelForFields(makeFields(trader_options));
