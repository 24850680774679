import TradingApi from "App/api/TradingApi";
import ApiErrorHandler from "App/api/ApiErrorHandler";
import max from "lodash.max";
import toNumber from "App/utils/toNumber";
import formatCurrencyNumber from "App/utils/formatCurrencyNumber";
import formatDateTime from "App/utils/formatDateTime";

export const getTraderConfigurations = () =>
  TradingApi()
    .listTraderConfigurations()
    .then((configurations) => configurations.filter((c) => c.enabled))
    .catch(ApiErrorHandler);

export const getAssets = ({ queryKey }) => {
  const [, { id }] = queryKey;
  const fetchDate = new Date();
  return TradingApi()
    .listTraderConfigurationAssets(id)
    .then(({ validity, assets }) => ({
      validity,
      assets,
      fetchDate,
      isEmpty: assets.length === 0,
    }))
    .catch(ApiErrorHandler);
};

export const getActions = ({ queryKey }) => {
  const [, { id, limit }] = queryKey;
  const fetchDate = new Date();

  return TradingApi()
    .listTraderConfigurationActions(id, limit)
    .then((actions) =>
      actions.map((a) => [
        formatDateTime(new Date(a.date)),
        JSON.stringify(a.action),
      ])
    )
    .then((actions) => ({ actions, fetchDate, isEmpty: actions.length === 0 }))
    .catch(ApiErrorHandler);
};

function cmp(a, b) {
  if (a > b) return 1;
  if (b > a) return -1;
  return 0;
}

function makeFromDate(timerange) {
  if (timerange === "all") {
    return null;
  }
  if (timerange === "day") {
    return new Date(Date.now() - 24 * 3600 * 1000).toISOString();
  }
  if (timerange === "week") {
    return new Date(Date.now() - 7 * 24 * 3600 * 1000).toISOString();
  }
  if (timerange === "month") {
    const from = new Date();
    const month = from.getMonth();
    from.setMonth(month - 1);
    if (from.getMonth() === month) from.setDate(0);
    from.setHours(0, 0, 0);
    from.setMilliseconds(0);
    return from.toISOString();
  }
  throw "Invalid timerange";
}

export const getBalances = ({ queryKey }) => {
  const [, { id, currency, timerange }] = queryKey;

  const to = null;
  const from = makeFromDate(timerange);
  const fetchDate = new Date();

  // time is in milliseconds, uPlot requires "seconds"
  return TradingApi()
    .listTraderConfigurationBalances(id, currency, from, to)
    .then((balances) =>
      balances
        .map((entry) => ({
          date: Date.parse(entry.date) / 1000.0,
          amount: toNumber(entry.amount),
        }))
        .sort((a, b) => cmp(a.date, b.date))
    )
    .then((sortedBalances) => {
      const timeRow = sortedBalances.map(({ date }) => date);
      const dataRow = sortedBalances.map(({ amount }) => amount);
      // Correctly size the axis width. XXX: Assume USD for now.
      const ySize = Math.max(
        50,
        12 * formatCurrencyNumber(Math.trunc(max(dataRow)), currency).length
      );

      const isEmpty = sortedBalances.length === 0;

      return { timeRow, dataRow, ySize, isEmpty, fetchDate, from, to };
    })
    .catch(ApiErrorHandler);
};
