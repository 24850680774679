<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";
  import traders from "App/stores/traders";
  import api_keys from "App/stores/api_keys";
  import TradingApi from "App/api/TradingApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import EditForm from "App/components/TraderConfigurations/EditForm";
  import SelectTrader from "App/components/TraderConfigurations/SelectTrader";

  let selectedTrader = null;

  let hasLoaded = false;

  async function load() {
    await traders.load();
    await api_keys.load(); // XXX: Cache it!
    hasLoaded = true;
  }

  onMount(async () => {
    await load().catch(ApiErrorHandler);
  });

  // XXX: Navigate to "Show"
  function navigateToShow() {
    navigate(`/account/trader_configurations`, { replace: true });
  }

  function onCancel() {
    navigateToShow();
  }

  async function doSubmitValid({
    api_key,
    comment,
    trading_threshold_permille,
    coin_ratios,
  }) {
    await TradingApi().createTraderConfiguration({
      trader_id: selectedTrader.id,
      api_key: api_key || null,
      options: {},
      comment: comment || "",
      trading_threshold_permille,
      coin_ratios,
    });
    navigateToShow();
  }

  async function onSubmitValid({
    api_key,
    comment,
    trading_threshold,
    coin_ratios,
  }) {
    await doSubmitValid({
      api_key,
      comment,
      trading_threshold_permille: trading_threshold,
      coin_ratios,
    }).catch(ApiErrorHandler);
  }

  async function onTraderSelected(trader) {
    selectedTrader = trader;
  }
</script>

{#if hasLoaded}
  {#if selectedTrader}
    <EditForm
      traderConfig="{null}"
      traderInfo="{selectedTrader}"
      apiKeys="{$api_keys}"
      onCancel="{onCancel}"
      onSubmitValid="{onSubmitValid}"
    />
  {:else}
    <SelectTrader
      traders="{$traders}"
      onCancel="{onCancel}"
      onTraderSelected="{onTraderSelected}"
    />
  {/if}
{:else}Loading...{/if}
