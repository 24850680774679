<script>
  export let profile;

  import TabularData from "App/components/TabularData";

  const rows = [
    ["Full name", profile.first_name + " " + profile.last_name],
    ["Email address", profile.email],
    ["Username", profile.username],
    ["Phone number", profile.phone || "Not verified yet"],
    ["Verification status", profile.is_verified ? "Verified" : "Not verified"],
  ];
</script>

<div class="card mt-6">
  <header class="card-header">
    <p class="card-header-title">Your Profile</p>
  </header>
  <div class="card-content is-paddingless">
    <TabularData numColumns="{2}" rows="{rows}" isBordered="{true}" />
  </div>
</div>
