<script>
  export let steps;
  import Icon from "mdi-svelte";
  import { mdiCheck } from "@mdi/js";
</script>

<ul class="steps">
  {#each steps as step}
    <li
      class="step-item"
      class:is-active="{step.active}"
      class:is-success="{step.success}"
      class:is-completed="{step.completed}"
    >
      <div class="step-marker">
        {#if step.completed}
          <span class="icon">
            <i class="mdi">
              <Icon path="{mdiCheck}" />
            </i>
          </span>
        {/if}
      </div>
      {#if step.details}
        <div
          class="step-details"
          class:is-completed="{step.completed}"
          class:is-info="{step.active}"
        >
          <p class="step-title">{step.details}</p>
        </div>
      {/if}
    </li>
  {/each}
</ul>
