import { writable } from "svelte/store";
import TradingApi from "App/api/TradingApi";
import ApiErrorHandler from "App/api/ApiErrorHandler";

const { subscribe, set, update } = writable([]);

function load() {
  return TradingApi().listApiKeys().then(set).catch(ApiErrorHandler);
}

function deleteKey(api_key) {
  return TradingApi()
    .deleteApiKey(api_key)
    .then((ok) => {
      if (ok) {
        update((keys) => keys.filter((k) => k.api_key != api_key));
      }
    })
    .catch(ApiErrorHandler);
}

function addKey({ exchange, api_key, api_secret, comment, customer_id }) {
  return TradingApi()
    .addApiKey({
      exchange,
      api_key,
      api_secret,
      comment,
      customer_id,
    })
    .then((ok) => {
      if (ok) {
        update((keys) =>
          keys.concat([
            { exchange, api_key, comment, references: 0, customer_id },
          ])
        );
      }
    })
    .catch(ApiErrorHandler);
}

export default {
  subscribe,
  load,
  deleteKey,
  addKey,
};
