<script>
  import InputFieldContainer from "./InputFieldContainer.svelte";
  import TextInputField from "./TextInputField.svelte";

  export let field;
  export let label;
  export let model;
  export let placeholder = "";
</script>

<InputFieldContainer label="{label}" error="{model.errorFor(field)}">
  <TextInputField field="{field}" model="{model}" placeholder="{placeholder}" />
</InputFieldContainer>
