import * as z from './base';
export class ZodBoolean extends z.ZodType {
    constructor() {
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        super(...arguments);
        // null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.toJSON = () => this._def;
    }
}
ZodBoolean.create = () => {
    return new ZodBoolean({
        t: z.ZodTypes.boolean,
    });
};
