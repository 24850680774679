<script>
  import Actions from "./Actions.svelte";

  export let trader_configuration_id;
  export let limit = 120;
</script>

<div class="mt-6">
  <Actions
    traderConfigId="{trader_configuration_id}"
    limit="{limit}"
    showMore="{false}"
  />
</div>
