<script>
  import { writable } from "svelte/store";
  import { navigate } from "svelte-routing";
  import FullscreenDialog from "App/components/FullscreenDialog";
  import SeparatedLinks from "App/components/SeparatedLinks";

  import EnterCredentials from "App/components/Login/EnterCredentials";
  import Login2 from "App/components/Login/Login2";
  import EnterTwoFactor from "App/components/Login/EnterTwoFactor";

  export let nextPage = null;

  let loginFailed = writable(null);

  function initialState() {
    return { type: "username_password" };
  }

  let state = initialState();

  function reset() {
    state = initialState();
    loginFailed.set(null);
  }

  function onCredentialsEntered({ credential_token, twofactor_check_token }) {
    if (twofactor_check_token) {
      state = {
        type: "2fa",
        credential_token,
        twofactor_check_token,
      };
    } else {
      state = {
        type: "login2",
        credential_token,
        twofactor_token: null,
      };
    }
  }

  function onTwoFactorCompleted({ twofactor_token }) {
    state = {
      type: "login2",
      credential_token: state.credential_token,
      twofactor_token,
    };
  }

  function onLogin2Completed() {
    state = { type: "logged-in" };
    if (nextPage) {
      navigate(nextPage, { replace: true });
    }
  }
</script>

<FullscreenDialog title="Log in to your account">
  {#if $loginFailed}
    <div class="notification is-danger">
      <button on:click="{reset}" class="delete"></button>
      {$loginFailed}
    </div>
  {/if}

  {#if state.type === 'username_password'}
    <EnterCredentials
      loginFailed="{loginFailed}"
      next="{onCredentialsEntered}"
    />
  {/if}

  {#if state.type === '2fa'}
    <EnterTwoFactor
      loginFailed="{loginFailed}"
      next="{onTwoFactorCompleted}"
      twofactor_check_token="{state.twofactor_check_token}"
    />
  {/if}

  {#if state.type === 'login2'}
    <Login2
      loginFailed="{loginFailed}"
      next="{onLogin2Completed}"
      credential_token="{state.credential_token}"
      twofactor_token="{state.twofactor_token}"
    />
  {/if}

  {#if state.type === 'logged-in'}Logged in{/if}

  <p slot="below">
    <SeparatedLinks
      links="{[{ href: '/account/register', title: 'Sign Up' }, { href: '/account/password/reset', title: 'Forgot Password' }, { href: '/help', title: 'Need Help?' }]}"
    />
  </p>
</FullscreenDialog>
