<script>
  import AddApiKeyModel from "App/models/AddApiKeyModel";
  import Card from "App/components/ui/Card";
  import ModelForm from "App/components/ModelForm";
  import { onMount } from "svelte";
  import api_keys from "App/stores/api_keys";
  import { navigate } from "svelte-routing";

  onMount(() => api_keys.load());

  const customValidationFn = (values) => {
    console.log("In custom validation fn");

    let duplicate_key = $api_keys.find(
      (api_key) => api_key.api_key == values.api_key
    );
    let errors = {};
    let count = 0;

    if (duplicate_key !== undefined) {
      errors.api_key = "Duplicate Key";
      count += 1;
    }
    if (count > 0) {
      return errors;
    } else {
      return undefined;
    }
  };

  let add_api_key_model = initial_api_key_model();

  function initial_api_key_model() {
    return AddApiKeyModel({}, customValidationFn);
  }

  async function addApiKey({
    exchange,
    api_key,
    api_secret,
    comment,
    customer_id,
  }) {
    await api_keys.addKey({
      exchange,
      api_key,
      api_secret,
      comment,
      customer_id,
    });

    add_api_key_model = initial_api_key_model();

    navigate("/account/api_keys", { replace: true });
  }

  function onCancel() {
    navigate("/account/api_keys", { replace: true });
  }
</script>

<Card title="Add API Key">
  <ModelForm model="{add_api_key_model}" onSubmitValid="{addApiKey}">
    <div class="field is-grouped" slot="buttons">
      <div class="control">
        <button type="submit" class="button is-block is-danger">
          Add API Key
        </button>
      </div>
      <div class="control">
        <button class="button is-block" on:click|preventDefault="{onCancel}">
          Cancel
        </button>
      </div>
    </div>
  </ModelForm>
</Card>
