import { Buffer } from "buffer/";
import { Authenticator } from "@otplib/core";
import { createDigest, createRandomBytes } from "@otplib/plugin-crypto-js";
import { keyDecoder, keyEncoder } from "@otplib/plugin-thirty-two";

if (typeof window === "object" && typeof window.Buffer === "undefined") {
  window.Buffer = Buffer;
}

const authenticator = new Authenticator({
  createDigest,
  createRandomBytes,
  keyEncoder,
  keyDecoder,
});

export default authenticator;
