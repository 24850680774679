<script>
  import UploadForm from "App/components/Verification/UploadForm";
  import VerificationApi from "App/api/VerificationApi";
  import ApiErrorHandler from "App/api/ApiErrorHandler";
  import StepsProgress from "./StepsProgress.svelte";

  export let onError;
  export let onSuccess;

  async function submitPassport({ file }) {
    try {
      let { ok, error } = await VerificationApi().uploadPassport(file);
      if (ok) {
        onSuccess();
      } else {
        onError(error);
      }
    } catch (ex) {
      ApiErrorHandler(ex);
    }
  }
</script>

<StepsProgress activeStep="passport" />
<p class="title">Upload passport</p>
<p class="subtitle">
  Please upload a photo copy of your passport (PNG, JPG, PDF up to 10MB)
</p>
<UploadForm onSubmitValid="{submitPassport}" />
