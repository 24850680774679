<script>
  export let title = "";
  export let actionButtonTitle;
  export let show;
  export let onCancel = function () {};
  export let onAction = function () {};

  import Icon from "mdi-svelte";
  import { mdiAlertCircle } from "@mdi/js";
</script>

<div class="modal" class:is-active="{show}">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{title}</p>
      <button
        class="delete"
        aria-label="close"
        on:click|preventDefault="{onCancel}"
      ></button>
    </header>
    <section class="modal-card-body">
      <div class="media">
        <div class="media-left">
          <span class="icon is-large has-text-danger">
            <i class="mdi mdi-48px">
              <Icon path="{mdiAlertCircle}" size="48px" />
            </i>
          </span>
        </div>
        <div class="media-content">
          <slot />
        </div>
      </div>
    </section>
    <footer class="modal-card-foot is-flex" style="justify-content: flex-end;">
      <button class="button" on:click|preventDefault="{onCancel}">
        Cancel
      </button>
      <button class="button is-danger" on:click|preventDefault="{onAction}">
        {actionButtonTitle}
      </button>
    </footer>
  </div>
</div>
