import * as z from './base';
// import { ZodUndefined } from './undefined';
// import { ZodNull } from './null';
// import { ZodUnion } from './union';
import { ZodErrorCode } from '../ZodError';
export class ZodArray extends z.ZodType {
    constructor() {
        super(...arguments);
        this.toJSON = () => {
            return {
                t: this._def.t,
                nonempty: this._def.nonempty,
                type: this._def.type.toJSON(),
            };
        };
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        // null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.min = (minLength, message) => this._refinement(Object.assign({ check: data => data.length >= minLength, code: ZodErrorCode.too_small, type: 'array', inclusive: true, minimum: minLength }, (typeof message === 'string' ? { message } : message)));
        this.max = (maxLength, message) => this._refinement(Object.assign({ check: data => data.length <= maxLength, code: ZodErrorCode.too_big, type: 'array', inclusive: true, maximum: maxLength }, (typeof message === 'string' ? { message } : message)));
        this.length = (len, message) => this.min(len, { message }).max(len, { message });
        this.nonempty = () => {
            return new ZodNonEmptyArray(Object.assign(Object.assign({}, this._def), { nonempty: true }));
        };
    }
    get element() {
        return this._def.type;
    }
}
ZodArray.create = (schema) => {
    return new ZodArray({
        t: z.ZodTypes.array,
        type: schema,
        nonempty: false,
    });
};
export class ZodNonEmptyArray extends z.ZodType {
    constructor() {
        super(...arguments);
        this.toJSON = () => {
            return {
                t: this._def.t,
                type: this._def.type.toJSON(),
            };
        };
        // opt optional: () => ZodUnion<[this, ZodUndefined]> = () => ZodUnion.create([this, ZodUndefined.create()]);
        // null nullable: () => ZodUnion<[this, ZodNull]> = () => ZodUnion.create([this, ZodNull.create()]);
        this.min = (minLength, message) => this._refinement(Object.assign({ check: data => data.length >= minLength, code: ZodErrorCode.too_small, minimum: minLength, type: 'array', inclusive: true }, (typeof message === 'string' ? { message } : message)));
        this.max = (maxLength, message) => this._refinement(Object.assign({ check: data => data.length <= maxLength, code: ZodErrorCode.too_big, maximum: maxLength, type: 'array', inclusive: true }, (typeof message === 'string' ? { message } : message)));
        this.length = (len, message) => this.min(len, { message }).max(len, { message });
    }
}
