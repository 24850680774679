<script>
  export let profile = null;
  export let title = null;
  export let content = null;
  export let isWide = false;
  export let noContainer = false;

  import Nav from "./Nav.svelte";
  import Footer from "./Footer.svelte";
  import { onMount } from "svelte";

  let isNarrow = !isWide;

  let y;
  onMount(() => {
    y = 0;
  });
</script>

<svelte:window bind:scrollY="{y}" />

<Nav profile="{profile}" />

<main>
  <div class="hero is-medium is-light">
    <div class="hero-body">
      <div class:container="{!noContainer}">
        <div class="columns">
          <div
            class="column"
            class:is-10="{isWide}"
            class:is-offset-1="{isWide}"
            class:is-three-fifths="{isNarrow}"
            class:is-offset-one-fifth="{isNarrow}"
          >
            <div class="content is-medium">
              {#if title}
                <h1 class="title mb-6">{title}</h1>
              {/if}
              {#if content}
                <svelte:component this="{content}" />
              {/if}
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<Footer />
