<script>
  export let field;
  export let model;

  import Icon from "mdi-svelte";
  import { mdiScaleBalance } from "@mdi/js";

  function balance(entry) {
    let total = field.entries.reduce(
      (acc, cur) => acc + (model.values[field.name][cur] || 0),
      0
    );
    let valueEntry = model.values[field.name][entry] || 0;
    let newValueEntry = Math.max(1000 - (total - valueEntry), 0);
    model.values[field.name][entry] = newValueEntry;
  }
</script>

{#each field.entries as entry}
  <div class="level">
    <div class="level-item" style="max-width: 6em;">
      <span class="tag">{entry}</span>
    </div>
    <div class="level-item">
      <input
        class="slider is-fullwidth"
        min="{field.min || 0}"
        max="{field.max || 100}"
        bind:value="{model.values[field.name][entry]}"
        step="{field.step || 1}"
        type="range"
      />
    </div>
    <div class="level-item" style="max-width: 6em;">
      <span class="tag is-light">
        {field.valueFormatter(model.values[field.name][entry])}
      </span>
    </div>

    <div class="level-item" style="max-width: 6em;">
      <button
        class="button"
        aria-label="balance ratio"
        on:click|preventDefault="{() => balance(entry)}"
      >
        <span class="icon is-small">
          <i class="mdi">
            <Icon path="{mdiScaleBalance}" />
          </i>
        </span>
      </button>
    </div>
  </div>
{/each}
